@import "../../../assets/variableStyles.scss";
.transport_search {
    .heading_text {
        top: 30% !important;
        margin-top: 85px !important;
        h2 {
            font-size: 20px !important;
            margin-bottom: 0 !important;
        }
    }

}