// .promo {
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.promo-modal-header {
  .ant-modal-title {
    color: #fff;
  }
  .ant-form-item-label {
    text-align: left !important;
  }
}
// }
