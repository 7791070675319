@import "../../assets/variableStyles.scss";
.dashboard {
  padding: 15px;
  .dash-board-header {
    display: flex;
    justify-content: space-between;
    .dash-board-title {
      h4 {
        font-size: 16px;
        font-weight: bold;
      }
      p {
        margin-bottom: 0;
        font-size: 14px;
      }
    }
    .dash-board-header-btn {
      button {
        background-color: #007bff;
        display: flex;
        border: none;
        align-items: center;
        padding: 7px 10px;
        color: #fff;
        border-radius: 5px;
        p {
          margin-left: 10px;
          margin-bottom: 0;
        }
      }
    }
  }
  .order-count-cards {
    .order-card {
      background-color: #007bff;
      border-radius: 5px;
      color: #fff;
    }
  }
}

/* dash--board--changes */

/* changes--dash--board */
.dash-board-icon-all {
  font-size: 42px !important;
  height: 56px !important;
  width: 56px !important;
  text-align: center !important;
  line-height: 56px !important;
  color: rgba(0, 0, 0, 0.15) !important;
}
.dash-boadrd-heading {
  font-size: 14px;
  margin: 5px 0;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}
.count-dashboard {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
}
.card-bg-1 {
  background-color: #348fe2 !important;
}
.card-bg-1 .ant-card-body {
  padding: 15px !important;
}

.card-bg-2 {
  background-color: #49b6d6 !important;
}

.card-bg-2 .ant-card-body {
  padding: 15px !important;
}

.card-bg-3 {
  background-color: #f59c1a !important;
}

.card-bg-3 .ant-card-body {
  padding: 15px !important;
}
.card-bg-4 {
  background-color: #ff5b57 !important;
}

.card-bg-4 .ant-card-body {
  padding: 15px !important;
}

.card-bg-5 {
  background-color: #ef9d9b !important;
}

.card-bg-5 .ant-card-body {
  padding: 15px !important;
}
.bottom-dashboad-bdr {
  display: block;
  margin: 4px -15px -15px;
  padding: 2px 15px;
  background: rgba(0, 0, 0, 0.4);
  text-align: right;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  border-radius: 5px;
}
.bottom-dashboad-bdr:hover {
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}

.bottom-dashboad-bdr-1 {
  display: block;
  margin: 4px -15px -15px;
  padding: 2px 15px;
  background: rgba(0, 0, 0, 0.6);
  text-align: right;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  border-radius: 5px;
}
.bottom-dashboad-bdr-1:hover {
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}

.bottom-dashboad-bdr-2 {
  display: block;
  margin: 4px -15px -15px;
  padding: 2px 15px;
  background: rgba(0, 0, 0, 0.4);
  text-align: right;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  border-radius: 5px;
}
.bottom-dashboad-bdr-2:hover {
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}
.bottom-dashboad-bdr-3 {
  display: block;
  margin: 4px -15px -15px;
  padding: 2px 15px;
  background: rgba(0, 0, 0, 0.4);
  text-align: right;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  border-radius: 5px;
}
.bottom-dashboad-bdr-3:hover {
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}
.bottom-dashboad-bdr-4 {
  display: block;
  margin: 4px -15px -15px;
  padding: 2px 15px;
  background: rgba(0, 0, 0, 0.6);
  text-align: right;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  border-radius: 5px;
}
.bottom-dashboad-bdr-4:hover {
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}
.dashboard-datails-p {
  margin-top: 3px;
  margin-bottom: 2px;
  cursor: pointer;
  color: #ffffff;
}
.hotel-sales-new {
  margin-top: 0px;
}
.flights-sales-card {
  font-weight: bold;
  font-size: 16px;
}
.side-bar-graph {
  margin-left: 13px;
  border-radius: 8px;
}
.side-bar-graph-1 {
  border-radius: 8px !important;
  border: 0px solid #f0f0f0 !important;
}
.side-bar-graph-new-flight {
  margin-left: 13px;
  border-radius: 8px;
  @include mobile {
    margin: 13px 0 0 0;
  }
}
.side-bar-graph-new-flight .ant-card-head {
  background-color: #1d2226 !important;
  border-radius: 8px;
}
.side-bar-graph-new-flight .ant-card-head-title {
  color: #ffffff;
}
.charts-bars {
  margin-top: 25px !important;
}
.source-flights {
  text-align: left;
}
.count-ratio li {
  padding-bottom: 10px;
}
.total-cal-fl {
  border-right: 1px solid #b8c1ca;
  padding-left: 3px;
}
.total-cal {
  padding-left: 8px;
  border-right: 1px solid #b8c1ca;
}
.boder-count {
  border: 1px solid #b8c1ca;
}
.month-wise-cart {
  font-weight: 700;
  font-size: 16px;
}
.pad-left-dashboard {
  padding-left: 13px;
}

.dash-board-column {
  padding-left: 4px;
}
.card-bg-b2 {
  background-color: #7a92af !important;
}
.card-bg-b2 .ant-card-body {
  padding: 15px !important;
}
