@import "../../assets/variableStyles.scss";

/* Datepicker Custom styles */
.modifySelect {
  @include mobile {
    margin: 10px 0;
  }
}
.react-datepicker-component {
  .react-datepicker-input {
    input {
      background: #fff !important;
      padding-left: 38px !important;
      padding-right: 0 !important;
    }
    .button-wrapper {
      position: absolute !important;
      left: 0 !important;
      right: initial !important;
      i {
        color: #0775e2;
      }
    }
  }
  .react-datepicker-input.has-value {
    input {
      font-weight: 100 !important;
      color: #505c75 !important;
    }
  }
}
/* End of Datepicker Custom styles*/

.flight_search_box {
  input {
    border-color: #bcd5f5;
  }
  background: #fff;
  padding: 20px 20px 15px 20px;
  border-radius: 5px;
  margin-top: 10px;
  @include mobile {
    padding: 15px;
  }
  .flight-header {
    margin-bottom: 4px;
    label {
      display: flex;
      align-items: flex-end;
      font-size: 14px;
      &:active {
        color: #1c2b39;
        font-weight: 700;
      }
    }
    .flight-class {
      label {
        font-size: 12px;
        margin-bottom: 7px;
      }
      .col {
        padding-left: 0;
        .custom-select {
          border: transparent;
          font-size: 12px;
          font-weight: 700;
          line-height: 19px;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .flight-body {
    .custom-column {
      margin-bottom: 0.5rem;
      position: relative;
      label {
        font-size: 11px;
        font-weight: 700;
        color: #1c2b39;
        margin-bottom: 0;
      }
      .flight-search-btn {
        background-color: #e2076a;
        color: #fff;
        border-color: transparent;
        &:hover {
          background-color: #eb5196;
        }
      }
      .city-increment {
        position: absolute;
        right: -20px;
        top: 50%;
      }
    }
    .return-date-col {
      .react-datepicker-component {
        .react-datepicker-input.has-value {
          &::after {
            content: "\f00d";
            font-family: FontAwesome;
            right: 10px;
            position: absolute;
            top: 0px;
            border-left: 1px solid #bcd5f5;
            padding-left: 10px;
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
          }
          &:hover {
            color: red !important;
          }
        }
      }
    }
  }
  .multi-city-wrapper {
    .city-count {
      width: 1%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .place-box {
      width: 36% !important;
      padding-right: 10px;
      padding-left: 10px;
    }
    .departure-date-box {
      width: 23%;
    }
    .city-increment {
      margin-left: 10px !important;
      align-self: center;
    }
  }
}
.plus-icon {
  font-size: 25px;
}

.flight-search {
  .ant-form-item-label {
    padding-bottom: 0;
    label {
      font-size: 11px;
      font-weight: 700;
      color: #1c2b39;
      height: 20px;
      display: none;
    }
  }
  .originwrapper {
    position: relative;
    .arrow-flight-inr {
      position: absolute;
      bottom: 0;
      top: 30%;
      right: -12px;
      z-index: 20;
      transition: all 0.5s ease 0s;
      cursor: pointer;
      @include mobile {
        bottom: 0;
        top: 80%;
        right: 15px;
        transform: rotate(90deg);
      }
    }
    .arrow-flight-inr:hover {
      transform: rotate(360deg);
      @include mobile {
        transform: rotate(450deg);
      }
    }
  }
}
.flight-passenger {
  .pax-modal {
    position: absolute;
    z-index: 1;
    left: -101px;
    background: #fff;
    width: 280px;
    @include mobile {
      left: 0;
    }
    .pax-modal-wrapper {
      padding: 10px 20px;
      border: 1px solid #bcd5f5;
      @include mobile {
        padding: 10px;
      }
      li {
        display: flex;
        list-style-type: none;
        border-bottom: 1px solid #dcdee3;
        margin-bottom: 2px;
        margin-top: 8px;
        padding-bottom: 8px;
        &:last-child {
          border-bottom-color: transparent;
        }
        .pax-label {
          flex-grow: 1;
          text-align: start;
          p {
            margin-bottom: -6px;
            font-size: 16px;
            font-weight: bold;
            color: #1c2b39;
          }
          span {
            font-size: 12px;
            font-weight: 400;
            color: #969dac;
          }
        }
        .pax-count {
          flex-grow: 1;
          align-self: baseline;
          display: flex;
          justify-content: space-around;
          align-self: center;
          .fa {
            background: #0775e2;
            padding: 4px;

            color: #fff;
            line-height: 1;
            vertical-align: sub;
          }
          .fa-minus {
            // border-top-left-radius: 4px;
            // border-bottom-left-radius: 4px;
            border-radius: 10px;
          }
          .fa-plus {
            // border-top-right-radius: 4px;
            // border-bottom-right-radius: 4px;
            border-radius: 10px;
          }
        }
      }
      .pax-ready-btn {
        border-radius: 3px;
        background: #fff;
        padding: 6px;
        color: #0775e2;
        font-size: 11px;
        font-weight: 700;
        border: 2px solid #0775e2;
        margin-top: 20px;
        &:hover {
          background: #0775e2;
          color: #fff;
        }
        @include mobile {
          margin: 12px 0 5px 0;
        }
      }
    }
  }
}
.primary-btn {
  cursor: pointer;
  background: #ea1b37 !important;
  text-align: center;
  display: block;
  color: #fff;
  font-size: 20px;
  outline: 0;
  border: 0;
  font-weight: 600;

  &:focus {
    border-color: #006bb1 !important;
  }
}
.from-to-inputs {
  .ant-row {
    flex-direction: column;
    align-items: flex-start;
    .ant-form-item-control {
      width: 100%;
    }
  }
}
.mr-none {
  margin-right: 0 !important;
  .mr-t {
    @include mobile {
      margin-top: 10px;
    }
  }
}
.class-container {
  display: flex;
  justify-content: space-between;
  @include mobile {
    flex-direction: column;
  }
  .select_wrapper {
    padding: 0 5px;
  }
  .class-type {
    font-size: 11px;
    margin-right: 7px;
    @include mobile {
      font-weight: 700;
    }
    .ant-radio-wrapper {
      @include mobile {
        width: 100%;
      }
    }
  }

  .ant-select-selector {
    border: none !important;
    font-size: 12px;
    font-weight: 700;
  }
}

/* .hide-icon-heading-two {
  display: none;
}
 */

/*-----flight--search--css-13-05--*/
.flight-bg-side {
  .ant-form-item {
    margin-bottom: 0px !important;

    .ant-select-lg {
      border-right: 1px solid #d6d3d3;

      @include mobile {
        border-right: 0px;
        border-bottom: 1px solid #d6d3d3;
      }
      .ant-select-arrow {
        display: none;
      }
      .ant-select-selector {
        height: 65px !important;
        padding: 14px 11px;
        border-color: transparent !important;
        border: 0px solid !important;
        border-right-width: 0px !important;
        border-radius: 0;
        color: #bfbfbf;
        .ant-select-selection-search-input {
          height: 65px !important;
          color:#000000;
        }
        .ant-select-selection-placeholder {
          border: 0;
          background: none;
          font-weight: 600;
          font-size: 15px;
          outline: medium none;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color:#000000;
        }
        .ant-select-selection-item {
          border: 0;
          background: none;
          font-weight: 600;
          font-size: 15px;
          outline: medium none;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: #000;
        }
      }
    }

    .ant-picker-large {
      padding: 11px 11px 6.5px !important;
      height: 65px;
      border: none;
      border-right: 1px solid #d6d3d3 !important;
      border-radius: 0;
      color: #bfbfbf;

      @include mobile {
        border-right: 0px;
        border-bottom: 1px solid #d6d3d3;
      }
      .ant-picker-input {
        input {
          color: #000;
          font-weight: 600;
        }
      }
      .ant-picker-input input::placeholder{
          color: black;
        }
    }

    .ant-input-lg {
      height: 65px;
      padding: 11px 11px;
      font-size: 16px;
      font-weight: bold;
      border: none;
      border-radius: 0;
      color: #000;
    }

    .ant-form-item-explain.ant-form-item-explain-error {
      color: #fff !important;
      font-weight: 500;
      @include mobile {
        display: none;
      }
    }
  }
  .destination {
    .ant-form-item {
      .ant-select-lg {
        .ant-select-selector {
          padding-left: 16px;
          @include mobile {
            padding-left: 11px;
          }
          .ant-select-selection-search {
            padding-left: 5px;
            @include mobile {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
  .originwrapper {
    .ant-form-item {
      .ant-select-lg {
        border-radius: 2px 0 0 2px;
      }
    }
  }
  .ant-btn {
    width: 100%;
    height: 65px;
    border-radius: 0 2px 2px 0;
    @include mobile {
      border-radius: 2px;
    }
  }
}

.pax-count-info {
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 66px;
}
.f-primary {
  margin-top: 0px !important;
  width: 100%;
  height: 66px;
}

.bdr-icon-f {
  font-weight: 600;
  border-radius: 20px;
  padding-left: 8px;
  color: #fff;
}

.tripTypeActive {
  background-color: #fff;
  color: #000;
}

.returnDate {
  .ant-picker.ant-picker-disabled,
  .ant-picker-input > input[disabled] {
    cursor: pointer !important;
  }
}
// .flight-bg-side .ant-radio {
//   display: none !important;
// }
.primary-btn:hover {
  background-color: #1e4167 !important;
}
.lowest-ne-p {
  font-size: 34px;
  font-weight: 700;
  color: #fff;
  font-family: "Ubuntu", sans-serif;
  text-align: center;
  @include mobile {
    font-size: 16px !important;
  }
}

/*20/09/2021*/
.agent-tt-btn {
  border: 1px solid #c3c1c1;
}
.second-agent-plan {
  padding-top: 25px;
}
.button-default-v3 {
  position: absolute;
  right: 28px;
}
.train-search-btn .ant-picker-input input::placeholder{
  color: black;
}
