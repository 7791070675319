.booking-option-cart {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
  color: #424242;
  padding-left: 0px;

  margin-top: 0px;
}
/* .booking-option-cart::before {
    content: '';
    position: absolute;
    top: 35.5%;
    left: 15px;
    width: 5px;
    height: 20px;
    background-color: #ff5722;
    border-radius: 5px;
    transform: translateY(-50%);
} */
.contact-info {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  color: #424242;
  padding-left: 0px;
  padding-top: 10px;
  margin-top: 20px;
}

/* .contact-info::before {
    content: '';
    position: absolute;
    top: 35.5%;
    left: 15px;
    width: 5px;
    height: 20px;
    background-color: #ff5722;
    border-radius: 5px;
    transform: translateY(-50%);
} */

.total-booking {
  padding: 12px 24px;
  line-height: 26px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  word-wrap: break-word;
  background-color: #f5f5f5;
}
.doument-name {
  height: 33px;
}
.form-rules {
  margin-top: 25px;
}
.now-total-btn {
  text-align: right;
}
.payment-complete {
  margin-top: 25px;
}

.info-item li {
  display: inline-block;
}
.date-credits {
  width: 215px;
  line-height: 18px;
  font-size: 14px;
  color: #999;
}
.date-item-icon {
  width: 30px;
  line-height: 18px;
  font-size: 14px;
  color: #999;
}
.date-item {
  width: 140px;
  line-height: 18px;
  font-size: 13px;
  color: #000000;
  font-weight: 600;
}

.date-item-1 {
  width: 140px;
  line-height: 18px;
  font-size: 14px;
  color: #000000;
  text-align: right;
  font-weight: 600;
}
.item-ctrd-two {
  margin-top: 25px;
}
.total-pay-cost-v {
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  color: #ff5722;
}
.rupee-cost {
  font-size: 20px !important;
  line-height: 20px;
  font-weight: bold;
  color: #ff5722 !important;
}
@media only screen and (max-width: 767px) {
  .mobile-offset {
    margin-left: 0% !important;
  }
  .mobile-row-res {
    margin-top: 15% !important;
  }
  .nextactivity {
    padding-left: 0px !important;
  }
}
.details-offer-card {
  margin-top: 25px;
}
.payment-boottom {
  margin-bottom: 0px !important;
}
.total-card-bar .ant-collapse-borderless {
  background-color: transparent !important;
}
.card-total-option .ant-card-body {
  padding: 0px !important;
}
.total-cost-user {
  border-bottom: 1px solid #b5b5b5;
}
.offdata {
  color: red;
}
.offdata-1 {
  font-size: 18px;
  font-weight: 400;
  color: #000;
}
.dataicon {
  font-size: 700;
  font-size: 18px;
}
.barselecticon {
  width: 97% !important;
}
.checkout-activity-card {
  border-radius: 8px;

  box-shadow: 0 2px 5px 0 #13296833;

  .info-item-flexbox {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      margin-bottom: 4px;
      flex: 1;
    }
  }
  .ant-form-item-control-input-content {
    .ant-checkbox-wrapper {
      text-align: left;
    }
  }

  .book-btn {
    padding: 0 20px;
    font-weight: 500;
    color: #fff;
    font-size: 18px;
    background-color: #ea1b37;
    border: none;
    height: 38px;
    line-height: 38px;
    border-radius: 5px;
  }
}
.activites-cheout-nav {
  background: #002172;
  padding: 15px 0;
}
.nextactivity {
  padding-left: 80px;
  color: #fff;
  cursor: pointer;
  margin-bottom: 0px;
}
.nextactivity a {
  color: #ffffff;
}
.activi-leftbat {
  padding-left: 8px;
  padding-right: 8px;
}
