.combined-bar-option {
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 #13296833;
}
.combined-bar-option .ant-card-body {
  padding: 6px !important;
}
.combinedtabs-panel .ant-tabs-tab-btn {
  font-size: 16px !important;
  font-weight: 600;
}

.buildpkg-width {
  width: 1300px !important;
}
@media only screen and (max-width: 768px) {
  .buildpkg-width {
    width: 100% !important;
  }
  .build-new-pdr-v3 {
    padding: 0px 0px !important;
  }
  .build-zoner-v3 {
    padding: 0px !important;
  }
}
.build-combined-hotel-acti .activitycnf {
  display: none;
}
.build-combined-hotel-acti .hotels_modify {
  display: none;
}
.build-combined-hotel-acti .bar-admin-width {
  width: 100% !important;
  padding: 0px 0px;
}
.build-combined-hotel-acti .ant-radio-wrapper {
  margin-left: 7px !important;
}
.conbined-bg-ha .ant-layout {
  background: transparent !important;
}

/*css-column--ad---*/
.hotel-build-hide .hotel-close-btn .nav {
  display: block !important;
  margin-left: auto;
  width: 14%;
}

.hotel-build-hide .hotel-close-btn .nav-link {
  text-align: center;
  background-color: #ea1b37;
  border-color: transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 3px;
  height: auto;
}

.hotel-build-hide .hotel-close-btn .hotels_search_list .results-wrapper {
  margin-bottom: 0px !important;
}

.hotel-build-hide .nav-link {
  display: block;
}

.hotel-build-hide .filters-box {
  display: none;
}

.hotel-build-hide .activites-bar-panel .nav {
  width: 100%;
  border: none;
}

.hotel-build-hide .activites-bar-panel .nav-link {
  text-align: center;
  background-color: #ea1b37;
  border-color: transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 3px;
  height: auto;
  max-width: fit-content;
  margin-left: auto;
}
.hotel-build-hide .hotel-times-fa {
  text-align: right;
  font-size: 20px;
  display: block;
}

.hotel-build-hide .hotels-box {
  width: 100% !important;
}
.hotel-build-hide .add-h-panel {
  display: block;
  margin-left: auto;
  background-color: #ea1b37 !important;
  border-color: #fff;
  color: #fff !important;
  height: 40px;
  padding: 0 24px;
  line-height: 28px;
  font-size: 14px;
  border-radius: 3px;
  font-weight: 700;
}
.hotel-build-hide .build-pkg-td {
  width: 9%;
}
.hotel-build-hide .new-sec-1key {
  border: 0px solid;
  padding: 0px 0px;
}
.hotel-build-hide .des-row-key {
  margin-top: 0px;
  padding-top: 0px;
}
.hotel-build-hide .build-pkg-td-last {
  width: 11%;
}
.trace-hotels {
  margin-top: 0px;
}

@media screen and (max-width: 768px) {
  .hotel-build-hide .activites-bar-panel .nav {
    width: 100% !important;
  }
  .hotel-build-hide .activites-bar-panel .web-choose-btn {
    display: none !important;
  }
  .hotel-build-hide .hotel-close-btn .web-choose-btn {
    width: 100% !important;
  }
  .tabs-left-bar .modal-dialog {
    min-width: 100% !important;
  }
  .tabs-left-bar-hotel .modal-dialog {
    min-width: 100% !important;
  }

  .hotel-build-hide .hotel-close-btn .nav {
    width: 100% !important;
  }

  .hotel-build-hide .hotel-close-btn .web-choose-btn {
    display: none !important;
  }
  .hotel-build-hide .hotel-close-btn .mobile-choose-btn {
    display: none !important;
  }
  .hotel-build-hide .hotel-close-btn .hotel-price {
    position: relative !important;
  }
  .hotel-build-hide .activites-bar-panel .hotel-price {
    position: relative !important;
  }
  .view-select-pkg-plan {
    top: 23% !important;
  }
  .buildpkg-width .activity-choose-btn {
    font-size: 14px !important;
    padding: 2px 6px !important;
  }
  .buildpkg-width .activity-price span {
    font-size: 16px !important;
  }
  .buildpkg-width .activity-price {
    margin-bottom: 0px !important;
  }
}

.hotel-build-hide .hotels-list-wrapper {
  padding: 0px !important;
}

.hotel-build-hide .hotels-list-wrapper {
  max-width: 1140px;
  margin: 0 auto;
}
.tabs-left-bar .modal-dialog {
  position: fixed !important;
  margin: auto !important;
  min-width: 320px;
  height: 100%;
  right: 0 !important;
  transform: translate3d(0%, 0, 0) !important;
}

.tabs-left-bar .modal-content {
  height: 100% !important;
  overflow-y: auto !important;
}

.tabs-left-bar .modal-body {
  padding: 15px 15px 80px !important;
}

/*Left*/
.tabs-left-bar .modal-dialog {
  //  left: -320px;
  transition: opacity 0.3s linear, left 0.3s ease-out !important;
}

.tabs-left-bar.fade.in .modal-dialog {
  left: 0 !important;
}
.tabs-left-bar .modal-header {
  display: block !important;
  padding: 5px 16px;
}

/*diss--css--*/
.tabs-left-bar-hotel .modal-dialog {
  position: fixed !important;
  margin: auto !important;
  min-width: 500px;
  height: 100%;
  right: 0 !important;
  transform: translate3d(0%, 0, 0) !important;
}

.tabs-left-bar-hotel .modal-content {
  height: 100% !important;
  overflow-y: auto !important;
}

.tabs-left-bar-hotel .modal-body {
  padding: 15px 15px 80px !important;
}

/*Left*/
.tabs-left-bar-hotel .modal-dialog {
  //  left: -320px;
  transition: opacity 0.3s linear, left 0.3s ease-out !important;
}

.tabs-left-bar-hotel.fade.in .modal-dialog {
  left: 0 !important;
}
.tabs-left-bar-hotel .modal-header {
  display: block !important;
}
.view-data-hotel-activity {
  background-color: #ea1b37 !important;
  border-color: #fff;
  color: #fff !important;
  border: none;

  cursor: pointer;
  display: inline-block;
  font-family: Arial;
  font-size: 14px;
  padding: 4px 4px;
  text-align: center;
  text-decoration: none;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
  border-radius: 5px;
}
.view-select-pkg-plan {
  position: fixed;
  right: 6px;
  top: 21%;
  z-index: 999;
}
.close-hide-show {
  font-size: 16px;
}

.popup-side-activity {
  row-gap: 0px;
  justify-content: flex-end;
  position: absolute;

  right: 0px;
  width: 50%;
  top: 40%;
}
.side-bg-activity {
  background-color: #fff;
  border-radius: 10px;
}

@-o-keyframes glowing {
  0% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    box-shadow: 0 0 10px #ff0000;
  }
  100% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
}

@keyframes glowing {
  0% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
  50% {
    background-color: #ff0000;
    box-shadow: 0 0 10px #ff0000;
  }
  100% {
    background-color: #b20000;
    box-shadow: 0 0 3px #b20000;
  }
}

.tabs-left-bar .ant-card-body {
  padding: 3px !important;
}
.tabs-left-bar .cms-pages-width {
  padding: 0 0px;
}
.tabs-left-bar h5 {
  font-size: 16px;
  padding: 5px 5px;
}
.tabs-left-bar .activi-hotelinfo {
  font-size: 14px !important;
}
.tabs-left-bar .hotel-table-name p {
  font-size: 12px;
  font-weight: normal !important;
}
.tabs-left-bar {
  .grandone-1 {
    font-size: 12px !important;
  }
  .grandone {
    font-size: 12px !important;
  }
}
.tabs-left-bar .table-bordered th,
.table-bordered td {
  font-size: 12px;
  padding: 0px 5px;
}
.tabs-left-bar .hotel-table-name {
  margin-top: 0px !important;
}
.tabs-left-bar .table {
  margin-bottom: 0px !important;
}
.tabs-left-bar .create-auotation {
  margin-top: 4px !important;
}
.tabs-left-bar .quotation-btn {
  padding: 0px 2px;
  font-size: 13px !important;
}
.tabs-left-bar .quotation-card {
  margin-bottom: 5px !important;
}
