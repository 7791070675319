@import "../../../assets/variableStyles.scss";
.transport-checkout-container {
    max-width: 1280px;
    padding-right: 15px;
    padding-left: 15px;
    margin: 15px auto;
    @include mobile {
        margin-top: 65px;
    }
}
.bus-img {
    height: 110px;
    width: 100%;
    @include mobile {
        height: 150px;
    }
    img {
        height: 100%;
        width: 100%;
    }
}
.transport-company-name {
    display: flex; 
    @include mobile {
        flex-wrap: wrap;
    }
    &:nth-child(1) {
        margin-bottom: 10px;
    }
    p {
        display: flex;
        align-items: center;
        font-size: 15px;
        &:nth-child(1) {
            margin-right: 20px;
            span {
                margin-right: 5px;
                font-size: 18px;
            }
        }
        &:nth-child(2) {
            span {
                margin-right: 5px;
                font-size: 20px;
            }
        }
    }
    .company-name {
        font-size: 20px;
        color: #000;
        margin-bottom: 0;
        @include mobile {
            font-size: 14px;
            margin-bottom: 5px;
            margin-top: 5px;
        }
    }
    .class-type {
        background-color: #3c99f9;
        color: #fff;
        margin-bottom: 0;
        padding: 5px 10px;
        border-radius: 5px;
        margin-left: 10px;
        @include mobile {
            width: 100%;
            justify-content: center;
            margin-left: 0;
        }
    }
}
.transport-card {
    border-bottom: 1px solid #ccc;
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.description-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mobile {
        flex-wrap: wrap;
    }
}
.description-text {
    p {
    margin-bottom: 0;
    font-size: 15px;
    }
    span {
        color: #0775e2;
    }
}
.view-more-btn {
    @include mobile {
        width: 100%;
        margin-top: 10px;
    }
    button {
    background-color: #0775e2;
    color: #fff;
    padding: 7px 15px;
    border-radius: 5px;
    border: none;
    @include mobile {
        width: 100%;
    }
    }
}
.description-block {
    background-color: #afb5f9;
    padding: 15px;
    border-radius: 5px;
    margin-top: 15px;
}
.ant-card-head-title {
    text-transform: uppercase;
    font-size: 18px;
}
.flight-booking-details-header {
    h2 {
        text-transform: uppercase;
        font-size: 18px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ccc;
    }
}
.charges {
    margin-top: 15px;
    ul {
        padding: 0;
        margin: 0;
    }
    li {
        display: flex;
        justify-content: space-between;
        color: #ccc;
        font-size: 15px;
        &:last-child {
            font-size: 20px !important;
            p {
            margin-bottom: 0;
            }
            span {
                color: #0775e2 !important;
                font-size: 20px !important;
            }
        }
    }
    .total-price {
        color: #000;
    }
}
.starts-on {
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
    p {
        color: #000;
        font-size: 18px;
    }
    ul {
        border-left: 4px solid #0775e2;
        li {
            color: #ccc;
            margin-left: 10px;
            font-size: 15px;
        }
    }
}
.flight-booking-details-form {
    margin-top: 20px;
}