.holidays-headings {
  text-align: center;

  color: #ffffff;
}
.holidays-trips {
  background-image: url("../../../assets/images/holidays/hyd-holidays.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 384px;
  width: 100%;
  margin-bottom: 50px;
}
.holidays-btn {
  display: block !important;
  margin: 0 auto;

  font-size: 20px !important;

  padding: 0px 50px !important;
  border: solid 1px #fff !important;
  border-radius: 20px !important;
  background-color: #ffffff !important;
  color: #ff5722 !important;
}
.all-pages-holidays {
  text-align: center;
  position: relative;
  top: 111px;
}
.all-pages-holidays p {
  color: #ffffff;
}
.tabs-holidays {
  align-items: normal;

  position: relative !important;
  top: -96px !important;
  /* margin: 0 auto -40px; */
  width: 1160px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.08);
  padding: 10px 20px !important;
}

.blur-img {
  flex-grow: 1;

  position: relative;
  overflow: visible;
  display: block;
  height: 280px;
  width: 100%;
}
.fiter-image {
  position: absolute;

  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.fiter-image h5 {
  color: #ffffff;
  padding: 0px 20px;
}
.fiter-image p {
  color: #ffffff;
  padding: 0px 20px;
}

/*---card---data--*/
.card-images {
  margin-top: 4%;
}

.locations-hol {
  line-height: 30px;
}

.locations-hol li {
  list-style: none;
}

.locations-hol li a {
  cursor: pointer;
  text-decoration: none;
  color: #333;
  font-weight: 500;
}

.locations-hol i {
  color: #f5a623;
}

.locations-hol .hol-1 {
  color: #f5a623;
}
.locations-hol .hol-2 {
  color: #999999;
}
.body-card .ant-card-body {
  padding: 14px !important;
}
.mg-bot {
  margin-bottom: 6px !important;
}
.travel-img {
  background: transparent !important;
}
.img-travels {
  width: 530px;
  height: 210px;
}
.flex-wrap {
  flex: 0 0 48% !important;
}
.content-box {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  padding: 24px 32px;
}
.to-cities {
  margin-top: 25px;
}
/* .price-range{
    margin-bottom: 20px;
} */
.sort-ranges {
  padding: 0px 6px;
}
.d-sort {
  display: flex !important;
}

.drop-icon {
  position: absolute;
  right: 36px;
}
.line-bar {
  border-top: 1px solid #e0e0e0;
}
.categories {
  margin-top: 20px;
}
.left-bar-line {
  border-left: 1px solid #e0e0e0;
  padding-left: 36px;
  position: relative;
}
.sub-drop-down {
  left: 265px;
}
.about-hyd {
  background-color: #fff;
  padding: 30px 30px;
}
.maps-hyd {
  width: 330px;
  height: 186px;
  cursor: pointer;
  border-radius: 3px;
}
.near-places-hyd {
  margin-top: 20px;
}
.locations-hol-1 {
  line-height: 7px;
}
.locations-hol-1 li p {
  font-size: 15px;
  text-align: center;
}
.explore-btn {
  text-align: center;
  margin-top: 25px;
}
.all-near-places {
  font-size: 18px !important;
  font-weight: 500 !important;
  padding: 8px 20px !important;
  background-color: #ffffff !important;
  border: solid 1px #ea1b37 !important;
  color: #ea1b37 !important;
  border-radius: 2px !important;
  height: auto !important;
}
.all-near-places:hover {
  background-color: #ea1b37 !important;
  color: #ffffff !important;
}
.view-all-btn {
  width: 85%;
  margin-top: 17px;
  font-size: 18px !important;
  font-weight: 500 !important;

  background-color: white !important;
  border: solid 1px #ea1b37 !important;
  color: #ea1b37 !important;
  border-radius: 2px !important;
  height: auto !important;
}
.view-all-btn:hover {
  background-color: #ea1b37 !important;
  color: #ffffff !important;
}
.tabs-holidays .anticon svg {
  display: block !important;
}
/*---card---data--close--*/

@media screen and (max-width: 768px) {
  .tabs-holidays {
    width: 100%;
  }
  .img-travels {
    width: 100%;
  }
  .body-card {
    width: 100% !important;
    margin-bottom: 20px !important;
  }
  .sort-ranges {
    margin-bottom: 10px;
  }
  .sort-by-option {
    width: 100%;
  }
  .view-all-btn {
    width: 100% !important;
    margin-bottom: 10px;
  }
}

.activites-calls {
  border-radius: 3px;
  font-size: 13px;

  text-decoration: none;
  font-weight: 400;
  text-transform: uppercase;
  color: #ea1b37 !important;
  border: solid 1px #ea1b37 !important;
  float: right;
  text-align: center;
  padding: 2px 13px;
}
.activity-cms {
  margin-bottom: 30px;
}
.nameofcmd {
  font-size: 14px;
  border: 1px solid #d2d2d2;

  font-weight: 500;
  border-radius: 20px;
}
.activites-calls:hover {
  background-color: #1e4167 !important;
  color: #ffffff !important;
}
.activites-calls .heartbre:hover {
  color: #ffffff !important;
}
.activitycnf {
  //background-color: #00164d !important;

  background-color: #2f81ed !important;
  background-image: linear-gradient(to right, #2f80ed, #56ccf2) !important;
  padding-top: 0px;
  padding-bottom: 0px;
  // height: 21em;
  //   width: 100%;
  //   min-height: 230px;
}
.modiinput {
  padding-left: 8px;
  padding-right: 8px;
}
.activiinfonow {
  height: 42px;
  line-height: 35px;
  font-weight: 700;
  font-size: 16px;
}
// .overflow-categerory {
//   height: 300px;
//   overflow: auto;
// }
@media screen and (max-width: 768px) {
  .activiinfonow {
    width: 100%;
  }
  .modiinput {
    padding-left: 0px;
    padding-right: 0px;
  }
  .modiiconbar {
    margin-top: 10px;
  }
}
.activites-bar-panel .nav-link {
  display: none;
  //display: block;

}
.activites-bar-panel .nav-tabs {
  border-bottom: 0px solid #dee2e6;
}

.activity-side-box .ant-card-body {
  padding: 3px !important;
}
.rating-pro span.fa.fa-star {
  padding: 0px 10px;
}
.top-collespe .ant-collapse {
  background: transparent;
  border: transparent;
}
.top-collespe .ant-collapse-content-box {
  padding: 4px !important;
}
.range-collapse-activity .ant-collapse {
  background: transparent;
  border: transparent;
}
.range-collapse-activity .ant-slider-handle {
  background: #3f51b5;
  border: 1px solid #3f51b5;
}
.inr-right-depo {
  float: right;
}
.width-activity-v3 {
  max-width: 1100px;
}
