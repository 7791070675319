@import "../../../assets/variableStyles.scss";
.hotel-checkout-wrapper {
  .form-header {
    background-color: #002172;
    .form-header-container {
      max-width: 1100px;
      padding: 0 15px;
      margin: auto;
      .form-header-text {
        padding: 25px 0;
        h2 {
          color: #fff;
          font-size: 24px;
          margin-bottom: 0;
          font-weight: 400;
        }
      }
    }
  }
  .form-body {
    background-color: #eaebee;
    .form-body-container {
      max-width: 1100px;
      padding: 15px;
      margin: auto;

      .hotel-detail-header-wrapper {
        display: flex;
        align-items: "center";
        justify-content: space-between;
        p {
          margin-bottom: 0px;
        }
      }

      .hotel-card-wrapper {
        border-radius: 8px;
        box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
        margin: 15px 0;
        padding: 16px;
        .ant-card-body {
          padding: 0;
        }
      }

      .form-body-login-content {
        background-color: #fff;
        padding: 15px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 2px 5px #ccc;
        .form-body-login-text {
          p {
            margin-bottom: 0;
          }
        }
        .form-body-login-btns {
          button {
            background-color: transparent;
            border: 1px solid #0775e2;
            padding: 7px 15px;
            border-radius: 3px;
            margin-right: 10px;
            font-size: 14px;
            &:last-child {
              margin-right: 0;
            }
            img {
              height: 20px;
              width: 20px;
              margin-right: 5px;
            }
            span {
              vertical-align: middle;
            }
            i {
              font-size: 20px;
              margin-right: 5px;
              vertical-align: middle;
            }
          }
        }
      }
      .guest-details-form {
        .room-title {
          font-size: 16px;
          font-weight: 600;
          color: #1c2b39;
        }
        .ant-form {
          .guest-input-wrapper {
            .guestsType {
              font-size: 14px;
              font-weight: 600;
              margin: 0;
              margin-bottom: 8px;
            }
            .ant-input-group-addon {
              width: 35%;
              padding: 0;
            }
            // .ant-form-item-control-input {
            //   .ant-input {
            //     border: 1px solid #bebebe;
            //     border-radius: 4px;
            //     font-family: lato;
            //     font-size: 14px;
            //     font-weight: 400;
            //     height: 40px;
            //     outline: 0;
            //     padding: 0 20px 0 10px;
            //     width: 100%;
            //   }
            // }

            // .ant-select-selector {
            //   border: 1px solid #bebebe !important;
            //   border-radius: 4px !important;
            //   font-family: lato;
            //   font-size: 14px;
            //   font-weight: 400;
            //   height: 40px !important;
            //   outline: 0;
            //   padding: 0 20px 0 10px;
            //   width: 100%;
            // }

            label {
              font-size: 14px;
              color: #505c75;
              font-weight: 600;
            }
          }
        }
      }
      .form-body-contact {
        margin: 30px 0;
        .contact-header {
          display: flex;
          align-items: flex-end;
          margin-bottom: 15px;
          .contact-title {
            h1 {
              font-size: 24px;
              font-weight: 400;
              color: #1c2b39;
            }
          }
        }
        .contact-internal-form {
          background-color: #fff;
          border-radius: 8px;
          .contact-body {
            padding: 15px;
            .contact-header {
              span {
                font-size: 16px;
                font-weight: 600;
                position: relative;
                color: #1c2b39;
                &:after {
                  content: "";
                  height: 1px;
                  background-color: #bcd5f5;
                  position: absolute;
                  width: 475px;
                  top: 50%;
                  margin-left: 35px;
                  left: 100%;
                }
              }
            }
            .contact-form {
              margin-top: 20px;
              margin-bottom: 10px;
              label {
                font-size: 14px;
                color: #505c75;
                font-weight: 600;
              }
              .form-bed-type {
                margin-bottom: 0;
              }
              .ant-input-group-wrapper {
                .ant-input-group-addon {
                  width: 35%;
                  padding: 0;
                  .ant-select-selector {
                    background-color: inherit !important;
                  }
                }
              }
            }
            .contact-disclaimer {
              p {
                margin-bottom: 0;
                font-size: 12px;
                color: #969dac;
              }
            }
          }
          .contact-footer {
            padding: 15px;
            margin-bottom: 0;
            font-size: 13px;
            color: #505c75;
            i {
              margin-right: 10px;
            }
            .contact-checkbox {
              display: flex;
              align-items: center;
              label {
                margin-left: 5px;
                margin-bottom: 0;
                font-size: 14px;
                span {
                  font-weight: 600;
                  color: #0775e2;
                }
              }
            }
            .bookandpay-btn {
              button {
                font-size: 18px;
                font-weight: 600;
                background-color: #eb5196;
                color: #fff;
                border: none;
                height: auto;
                padding: 10px 30px;
                border-radius: 3px;
                &:hover {
                  background-color: #eb5196;
                }
              }
            }
          }
        }
      }
      .form-important-info {
        margin: 30px 0;
        .contact-header {
          display: flex;
          align-items: flex-end;
          margin-bottom: 15px;
          .contact-title {
            h1 {
              font-size: 24px;
              font-weight: 400;
              color: #1c2b39;
            }
          }
        }
        .contact-internal-form {
          background-color: #fff;
          border-radius: 5px;
          .contact-body {
            padding: 15px;
            .contact-header {
              span {
                font-size: 16px;
                font-weight: 600;
                position: relative;
                color: #1c2b39;
                &:after {
                  content: "";
                  height: 1px;
                  background-color: #bcd5f5;
                  position: absolute;
                  width: 475px;
                  top: 50%;
                  margin-left: 35px;
                  left: 100%;
                }
              }
            }
            .contact-form {
              margin-top: 20px;
              margin-bottom: 10px;
              label {
                font-size: 14px;
                color: #505c75;
              }
              .form-bed-type {
                margin-bottom: 0;
              }
            }
            .contact-disclaimer {
              p {
                margin-bottom: 0;
                font-size: 12px;
                color: #969dac;
              }
            }
          }
          .contact-footer {
            padding: 15px;
            margin-bottom: 0;
            font-size: 13px;
            color: #505c75;
            i {
              margin-right: 10px;
            }
            .contact-checkbox {
              display: flex;
              align-items: center;
              label {
                margin-left: 5px;
                margin-bottom: 0;
                font-size: 14px;
                span {
                  font-weight: 600;
                  color: #0775e2;
                }
              }
            }
          }
        }
      }

      .hotel-price-wrapper {
        .hotel-details-box {
          margin-bottom: 5px;
        }
        .promo-card {
          margin-top: 5px;
        }
        .grand-total-card {
          .ant-card-body {
            padding: 15px;
            .promo_added_fee {
              padding: 8px 0;
            }
            .total-fare {
              padding: 8px 0;
            }
          }
        }
      }
      // .form-body-right-content {
      //   background-color: transparent;
      //   border-radius: 8px;
      //   margin-bottom: 5px;
      //   .hotel-fair-box {
      //     background-color: #ffffff;
      //     border-radius: 8px;
      //     margin-bottom: 7px;
      //     .accordion {
      //       .cards {
      //         background-color: transparent;
      //         .card-header {
      //           background-color: transparent;
      //         }
      //       }
      //     }
      //   }
      // }
    }

    .book-pay-card {
      padding: 16px 24px;
      box-shadow: 0 2px 2px 0 #dcdee3;
      border: none;
      @include mobile {
        padding: 16px;
      }
      .book-pay-tc {
        display: flex;
        span {
          color: red;
        }
        p {
          margin-bottom: 0;
        }
      }
      .book-pay-offers {
        display: flex;
        p {
          font-size: 15px;
          a {
            &:hover {
              color: #007bff;
            }
          }
        }
      }
      .book-pay-btn {
        button {
          background-color: #ea1b37;
          color: #fff;
          border: none;
          font-size: 20px;
          font-weight: 600;
          padding: 10px 20px;
          height: 100%;
          @include mobile {
            font-size: 16px;
            padding: 10px 15px;
          }
        }
        .book-and-content {
          padding-top: 20px;
          p {
            font-size: 15px;
            color: #969dac;
          }
        }
      }
    }
  }
}
/****** Styles *****/

@media screen and (max-width: 768px) {
  .form-body-row {
    display: block !important;
  }
  .form-body-left-block {
    max-width: 100% !important;
  }
  .mobile-diretion-part {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .mobile-diretion-part .ant-form-item {
    margin-bottom: 0px;
  }
}

/****** Styles***17-08-2021*****/

.hotel-checkout-wrapper .hotel-stay-date-row {
  display: flex;
}
.hotel-collaspe-spin {
  margin-bottom: 15px;
  background-color: #ffffff;
  border: 0px solid #d9d9d9;
  border-radius: 8px;
}
.hotel-collaspe-spin .ant-collapse-header {
  color: #000;
  font-size: 15px;
  font-weight: 900;
}

.booking-summary-div {
  font-size: 20px;
  color: #1c2b39;
  // background-image: linear-gradient(to right, #43264e, #ea2331);

  // -webkit-text-fill-color: transparent;
  // -webkit-background-clip: text;
  // background-color: #fff;

  // background-image: -moz-linear-gradient(left, #43264e, #ea2331);
  // background-image: -ms-linear-gradient(left, #43264e, #ea2331);
  // background-image: -o-linear-gradient(left, #43264e, #ea2331);

  // -webkit-background-clip: text;
  // background-clip: text;
  // -webkit-text-fill-color: transparent;
}

.hotel-checkout-wrapper .image-details,
.hotel-preview-container .image-details {
  max-width: 210px !important;
}

@media only screen and (max-width: 768px) {
  .hotel-checkout-wrapper .image-details,
  .hotel-preview-container .image-details {
    max-width: 100% !important;
  }
}

.cp-review-book {
  padding-top: 20px;
}

.cp-note-imp {
  background-color: #fffcc7;
  padding: 5px;
}
.cp-info-note {
  padding-top: 10px;
  font-size: 15px;
}
