.visa-search-1{
    margin-top: 30px;
}
.visa-poplar{
    text-align: center;
    padding-bottom: 25px;
}
.visa-card{
    background-color: #fff;
    border-radius: 2px;
    width: 92%;
    transition: .3s ease-out;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.visa-card:hover{
    transform: scale(1.1,1.1);
    box-shadow: 3px 3px 35px -21px rgba(0,0,0,0.77);
    z-index: 9999;
    cursor: pointer;
    background-color: #fff;
}
.visa-pkg-name{
    width: 100%;
    float: left;
    text-align: center;
    font-size: 25px;
    color: #000;
    font-weight: 500;
    margin: 0;
}

.visa-pkg-name span{
    width: 100%;
    float: left;
    text-align: center;
    color: #9B9B9B;
    font-size: 15px;
    font-weight: normal;
    padding-top: 5px;
}
.visa-pkg-price{
    width: 100%;
    float: left;
    text-align: center;
    font-size: 25px;
    padding-top: 30px;
    font-weight: 600;
}
.orange-color{
    color: #D96554;
    border-bottom: 3px solid #D96554;
}
.blue-color{
    color: #4DD0DA;
    border-bottom: 3px solid #4DD0DA;
}
.violet-color{
    color: #784DDA;
    border-bottom: 3px solid #784DDA;
}
.yellow-color{
    color: #F3AF3E;
    border-bottom: 3px solid #F3AF3E;
}
.applying-visa{
    background-color: #ffb74d !important;
    padding-top: 80px;
    padding-bottom: 30px;
}
.simple-visa{
    font-size: 20px;
    color: #000;
    font-weight: 600;
    text-align: center;
}
.choose-section{
    margin-top: 30px;
}
.central-asia li a{
    color: #929292;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
    border-right: 1px solid #929292;
    line-height: 12px;
}
.asia-two{
    margin-top: 20px;
}
.all-tabs-country .ant-tabs-tab{
    font-size: 20px !important;
}



@media screen and (max-width: 768px){

    .hotels_search_container .hotel_banner .hotel_search {
        position: static;
        transform: none;
    }
    .hotels_search_container .hotel_banner .heading_text {
        top: 8px;
        padding: 24px 16px;
        bottom: auto;
    }
    .hotels_search_container {
        margin-top: 41px;
    }
    .hotels_search_container .hotel_banner .heading_text {
        position: static;
        transform: none;
        //background-color: #002172;
    }
    .hotels_search_container .hotel_banner .heading_text h2 {
        font-size: 16px;
        font-weight: 700;
    }
    .hotels_search_container .hotel_banner .hotel_search {
        position: static;
        transform: none;
    }
    
    }