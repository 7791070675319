.card {
  background: white;
  padding: 16px;
  border: 0;
}
.rowcard {
  background: white;
  padding: 16px;
  text-align: center;
}
