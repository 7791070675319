@import "../../assets/variableStyles.scss";

.combine-roundtrip-view {
  margin-bottom: 12px;
  .flight-results-container {
    height: auto;
  }
  .choose-btn-block {
    height: 100%;
  }
}

.result_div {
  // padding: 100px;
  padding: 23px 62px;
  // background-color: #ece6e6;
  // min-height: 1500px;
}
.result-bar {
  // float: left;
  .flight-results-container {
    height: 100%;
  }
  .combine-roundtrip-view .flight-results-container {
    height: auto;
  }
}
.result-div {
  border-radius: 3px;
  background-color: #fff;
  padding: 5px;
  border: none;
  // display: flex !important;
  // flex-direction: row !important;
}
// .result-div-left{
//     width: 75%;
//     float: left;
//     height: 100%;
//     padding-top: 10px;
// }
// .result-div-right{
//     border-left: solid 1px #c3bdbd;
//     width: 25%;
//     float: left;
//     // padding: 10px 45px;
//     display: flex;
//     justify-content: center;
//     flex-direction: column;
//     align-items: center;
//     padding: 5px 53px;
//     text-align: center;
// }
.result-div-top {
  display: flex;
  justify-content: space-between;
  padding: 0px 12px;
  // padding: 2px 20px;
  @include mobile {
    padding: 2px 7px;
    margin-top: 5px;
    border-top: solid 1px #ccc;
    padding-top: 17px;
  }
}
.airlineImg-src-dest {
  display: flex;
}

.result-div-bottom {
  // height: 60%;
  text-align: left;
  padding-top: 5px;
  padding-left: 5px;
}
.fromTo {
  display: flex;
  // align-items: center;
  // font-size: x-large;
  // margin-left: 20px;
  @include mobile {
    margin-left: 0px;
    font-size: 15px;
    font-weight: 700;
    flex-direction: column;
  }
  .code {
    font-weight: 200;
    font-size: 18px;
  }
}
.arrive-dept-date {
  margin-top: 3px;
  line-height: 1;
}
.dateDay {
  color: gray;
  font-size: 10px;
  margin: 2px 0px 2px 10px;
}
.flight-class-details {
  // margin-left: 114px;
  display: flex;
  flex-direction: column;
  text-align: right;
  // justify-content: right;
  @include mobile {
    // margin-left: 25px;
  }
}
.stopsType {
  // float: right;
  font-weight: 900;
  font-size: 11px;
  letter-spacing: 0.5px;
}
.flight-class {
  font-size: 10px;
  font-weight: 600;
}
.details {
  color: #0775e2;
  font-weight: 500;
  font-size: smaller;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.timings {
  font-weight: 700;
  font-size: 100%;
  font-family: "Ubuntu,sans-serif";
  color: #1c2b39;
}
.duration {
  color: #969dac;
  font-size: smaller;
}
.currency {
  font-weight: 700;
  font-size: 25px;
  @include mobile {
    font-size: 20px;
  }
}
.right-price-section {
  // width: 110px;
  line-height: 1;
  display: none;
}
.price-text {
  font-size: 11px;
}
.choose-btn-section {
  .choose-web-btn {
    @include mobile {
      display: none;
    }
  }
  .choose-mobile-btn {
    display: none;
    @include mobile {
      display: block;
    }
  }
}
.choose-button {
  background-color: #ea1b37;
  border: none;
  border-radius: 3px;
  color: white;
  font-size: 15px;
  font-weight: 700;
  padding: 6px 13px;
  margin: 10px 0px;
  i {
    font-size: 25px;
    font-weight: 600;
  }
}
.arrowAlignment {
  float: right;
  padding: 10px 10px 0px 0px;
}

/*  Flight Details Modal Styles Starts */

.details-modal {
  .modal-content {
    padding: 0px !important;
  }
}

.modal-custom-header {
  background-color: var(--color-1-darker-1, #00164d);
  padding: 2px 20px 0px 9px;
  align-items: center;
  p {
    margin-bottom: 0px;
    color: #fff;
    font-size: 100%;
  }
  button {
    span {
      color: #fff !important;
    }
  }
}
.modal-custom-body {
  padding: 0px !important;
  overflow: scroll;
  height: 400px !important;
  hr {
    margin: 0px;
  }
  .modal-part {
    padding: 16px 24px;
    p {
      font-size: 12px;
      margin-bottom: 0px;
    }
    .flight-directions {
      display: flex;
      justify-content: space-around;
      .direction-text {
        .dir-name {
          font-weight: 400;
          font-size: 27px;
        }
        .time {
          span {
            font-weight: 700;
          }
        }
      }
      .dir-line {
        display: flex;
        hr {
          width: 39px;
          margin-top: 1.6rem;
          background-color: #00d2e8;
        }
        .dir-dot {
          width: 8px;
          height: 8px;
          border-radius: 25px;
          background-color: #00d2e8;
          margin: 22px 6px;
        }
      }
      .dir-line-text {
        text-align: center;
        margin-top: -17px;
        p {
          font-size: 10px;
        }
      }
    }
    .direction-attributes {
      display: flex;
      justify-content: space-evenly;
      .trip-time {
        span {
          color: #ccc;
          font-size: 14px;
        }
        p {
          font-size: 15px;
        }
      }
    }
  }
  .flight-details-section {
    background-color: #f6f7f8;
    padding-left: 60px;
    .trip-name-details {
      display: flex;
      p {
        font-size: 16px;
      }
      .departure-details {
        padding-left: 38px;
        display: flex;
        p {
          font-size: 13px;
          span {
            font-weight: 700;
          }
        }
      }
    }
    .details-section {
      // position: relative;
      .flight-panel {
        background-color: #fff;
        border: none;
        margin-top: 16px;
        margin-bottom: 16px;
        .card-custom-header {
          background-color: #fff;
          button {
            border: none;
            width: 100%;
            background: #fff;
            &:focus {
              outline: none !important;
            }
          }
          .airline-details {
            display: flex;
            .airline-name {
              text-align: left;
              padding-left: 10px;
              line-height: 1.2;
              p {
                font-size: 20px;
              }
              span {
                font-size: 13px;
              }
            }
          }
          .airline-timing {
            display: flex;
            justify-content: space-around;
            .airline-code {
              display: flex;
              padding-top: 12px;
              .icon,
              i {
                font-size: 18px;
                color: #505c75;
                padding: 5px 6px 0px 0px;
              }
              p {
                font-size: 15px;
              }
            }
            .angle-down {
              i {
                padding-top: 12px;
                font-size: 23px;
              }
            }
          }
        }
        .acc-body {
          .acc-body-section {
            display: flex;
            padding: 7px 2px;
            i,
            .icon {
              padding-right: 7px;
              color: #1da023;
            }
            p {
              color: #1da023;
            }
          }
          .seat-sec {
            i {
              padding-right: 7px;
              color: #505c75;
            }
            p {
              color: #505c75;
            }
          }
          .flight-class {
            .ec-class {
              padding-left: 12px;
            }
            span {
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .change-section {
    background: #eaebee;
    padding: 28px 62px;
    display: flex;
    .change-info {
      span {
        color: #e0760b;
        padding-left: 12px;
      }
      i {
        color: #e0760b;
        border: solid 1px #e0760b;
        padding: 2px 6px;
        border-radius: 25px;
      }
    }
    .inter-text {
      padding-left: 20px;
      padding-top: 4px;
      span {
        font-weight: 600;
      }
    }
  }
}

.flight-src-destination {
  position: relative;
  // ::before {

  // }
}
.flight-top-dot::before {
  top: -11px !important;
}
.flight-src-destination::before {
  display: block;
  content: "";
  position: absolute;
  top: 18px;
  left: -31px;
  bottom: 0;
  background: #00d2e8;
  width: 2px;
}
.trip-append-dot {
  position: relative;
}
.trip-append-dot::before {
  display: block;
  height: 6px;
  position: absolute;
  content: "";
}
.dot-position::before {
  border: 3px solid #00d2e8;
  background: none;
  width: 12px;
  height: 12px;
  left: -36px;
  top: 6px;
  border-radius: 25px;
}
.padd-right-0 {
  padding-right: 0px;
}

/*  Flight Details Modal Styles End */
