.modal-login-new-1 {
  border-radius: 8px;
  width: 368px !important;
  height: auto;
  .ant-modal-content {
    border-radius: 8px !important;
  }
  .ant-modal-close-x {
    color: #000 !important;
  }
  .otp-shadow {
    box-shadow: none !important;
    padding: 30px 20px 6px 20px;
    background: #fff;
    margin-bottom: 0 !important;
    text-align: center;
    p {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }
  .otp-margin-bottom {
    .ant-input {
      letter-spacing: 2.5px;
      font-weight: 600;
      text-align: center;
      border-color: #efefef !important;
      color: #434343;
      box-shadow: 0px 0 16px rgba(0, 0, 0, 0.1);
      &::placeholder {
        color: #707070;
      }
    }
  }
  .google-btn {
    width: 100%;
    border-radius: 8px;
    font-weight: 600;
    height: 48px;
    font-size: 14px;
    background-color: #e12001 !important;
    color: #fff !important;
    border-color: #e12001 !important;
  }
  .google-btn:hover {
    color: #ffffff !important;
    border-color: #e12001 !important;
    background-color: #e12001 !important;
  }
  .otp-img-1 {
    width: 80px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .otp-shadow-1 {
    padding: 5px;
  }
  .otp-shadow-value {
    box-shadow: 0px 0 16px rgba(0, 0, 0, 0.1);
    padding: 9px;
    background: #fff;
    margin-bottom: 3px;
  }
  .otp-img {
    width: 110px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .ant-modal-footer {
    display: none;
  }
  .countdown {
    text-align: center;
  }
}
.modal-login-new-1 .ant-statistic-content-value {
  color: #e12001 !important;
}
