.buildyourpackage-section {
  .buildyourpackage-bg {
    min-height: 230px;
    // background-image: url("../../assets/images/hero_image.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    width: 100%;
    .coming-soon {
      text-align: center;
    }
  }
}
.build-sectionpage {
  background: #ffffff;
  margin-top: 120px;
  padding: 0px 0px;
  border-radius: 3px;
  height: 67px;
}
.build-sectionpage .ant-form-item-label {
  padding: 0 0 0px !important;
}

.pdr-bootom-cities {
  margin-bottom: 30px;
}
.build-sectionpage .ant-form-item {
  margin-bottom: 0px !important;
}
.build-row-second {
  padding-top: 11px;
}
.build-sectionpage .ant-select-selection-item {
  padding: 17px 0px !important;
}
.last-build-btn {
  display: none;
}
@media screen and (max-width: 768px) {
  .build-sectionpage {
    margin-top: 46px !important;
  }
  .buildyourpackage-bg {
    height: 100% !important;
    padding-bottom: 25px;
  }
  .last-build-btn {
    display: block;
  }
  .first-build-btn {
    display: none;
  }
  .build-sectionpage {
    height: auto !important;
  }
  .ha-build {
    margin-bottom: 5px;
  }
  .one-pkg-data-2 {
    border-top: 1px solid #ededed;
  }
  .nation-build {
    border-top: 1px solid #ededed;
  }
  .build-sectionpage-new {
    height: auto !important;
  }
  .pdr-pkg-v3 {
    padding: 0px 0px !important;
  }
}
.build-sectionpage .ant-select-selection-search {
  line-height: 60px;
  color: #000;
}
.modify-activity-hotel .ant-select-selection-item {
  line-height: 65px !important;
}
.modify-activity-hotel .ant-select-selection-search {
  line-height: 65px !important;
}
.modify-activity-hotel .ant-select-selection-placeholder {
  line-height: 65px !important;
}
.build-sectionpage-new {
  background: #ffffff;
  margin-top: 10px;
  padding: 0px 0px;
  border-radius: 5px;
  height: 67px;
  .buildautocomplete {
    .ant-select-selector {
      height: 65px !important;
      padding: 14px 11px !important;
      border-color: transparent !important;
      .ant-select-selection-search {
        line-height: 65px;
        color: #000;
      }
      .ant-select-selection-item {
        color: #000;
        font-weight: 600;
        font-size: 15px;
      }
    }
  }
  .ant-select-selection-item {
    color: #000;
    font-weight: 600;
    font-size: 15px;
  }
}
.build-sectionpage-new .ant-form-item {
  margin-bottom: 0px !important;
}
.build-sectionpage-new .ant-form-item-label {
  padding: 0 0 0px !important;
  line-height: 0px !important;
}
.inner-parameters .ant-layout {
  background: transparent !important;
}
.build-sectionpage input {
  font-weight: bold;
}
.build-sectionpage .ant-select-selection-item {
  font-weight: bold;
}
.second-bottom-row {
  padding-top: 0px !important;
}
.cms-pages-width-bdpkg {
  width: 100%;
  max-width: 1040px;
}
.build-top-hotels {
  padding-top: 58px !important;
}
.label-bd-pkg .ant-input-lg {
  border-radius: 30px;
  height: 33px;
  margin-top: 5px;
}
.build-rooms-pkg {
  margin-top: 10px;
  .ant-input-lg {
    border-radius: 30px;
    height: 33px;
    margin-top: 5px;
  }
}
.label-bd-pkg input {
  font-weight: bold;
}
// .build-menu-ah .ant-checkbox {
//   padding-left: 7px;
// }
.build-menu-ah span {
  line-height: 23px;
}
.hide-popup-build .img-pop-login {
  display: none;
}
.build-pkg-ccc {
  height: 21em;
  width: 100%;
  min-height: 230px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 10px;
  padding-bottom: 11px;
}
.cc-pkg-build {
  padding-top: 90px;
}
@media only screen and (max-width: 768px) {
  .build-pkg-ccc {
    height: 100% !important;
  }
  .cc-pkg-build {
    padding-top: 0px !important;
  }
}
/* ============  customize the react slick styles ================= */
.buildpack-slider {
  .slick-next::before {
    content: "\f105";
    cursor: pointer !important;
    font-size: 20px !important;
    color: #fff;
    font: normal normal normal 40px/1 FontAwesome;
  }
  .slick-next {
    cursor: pointer !important;
    line-height: 0 !important;
    opacity: 1 !important;
    z-index: 1;
    background: #52c4f1 !important;
    border: 0px;
    border-radius: 0px;
    display: inline-block !important ;
    font-size: 34px;
    font-weight: 200;
    padding: 4px 10px;
    height: 30px !important;
    width: 30px !important;
    position: absolute;
    top: 120px;
    right: -28px;
  }

  .slick-prev::before {
    content: "\f104";
    cursor: pointer !important;
    font-size: 20px !important;
    font: normal normal normal 40px/1 FontAwesome;
    color: #fff;
    opacity: 0.75 !important;
  }

  .slick-prev {
    cursor: pointer !important;
    line-height: 0 !important;
    opacity: 1 !important;
    z-index: 1;
    background: #52c4f1 !important;
    border: 0px;
    border-radius: 0px;
    display: inline-block !important;
    font-size: 34px;
    font-weight: 200;
    padding: 4px 10px;
    height: 30px !important;
    width: 30px !important;
    position: absolute;
    top: 120px;
    left: -35px;
  }
}
.build-sectionpage-new .ant-select-selection-placeholder {
  font-weight: bold;
  color: #000;
}
.build-sectionpage-new input {
  font-weight: bold;
}

.biuild-input{
  .ant-picker-input input::placeholder{
    color: black;
  }
}