.blog_wrapper {
  max-width: 1100px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  .blog_grid {
    padding: 24px 0;
    h2 {
      text-align: center;
    }
    .card {
      border-radius: 10px;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
      .img {
        height: 150px;
        width: 100%;
        border-radius: 10px 10px 0 0;
      }
      .ant-card-body {
        padding-top: 25% !important;
        padding: 0px;
        padding-bottom: 0px !important;
        .title {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 0px;
          padding-top: 10px;
          text-align: center;
        }
      }
    }
    .btn_wrapper {
      text-align: center;
      .ant-btn {
        background: #ea1b37 !important;
        color: #fff;
        font-weight: 600;
        height: auto;
        padding: 12px 20px;
      }
    }
  }
}
