.ticket-lti {
  color: #d5436a;
  font-size: 18px;
  font-weight: 700;
}
.retrieve-booking .type {
  font-size: 16px;
  font-weight: 700;
  color: #d5436a !important;
  margin-top: 20px;
  margin-bottom: 0;
}
.airline-dte-tic h4 {
  margin-bottom: 0px;
}

.airline-dte-tic h5 {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 600;
}
.airline-dte-tic {
  display: flex;
  align-items: center;
}

.airline-dte-tic span {
  font-size: 14px;

  font-weight: 600;
  margin-left: 10px;
}
.tic-indi-details {
  display: flex;
}
.tic-indi-details p {
  font-weight: 700;
  color: #000;
  margin: 0;
}
.airline-tic-files {
  font-size: 12px;
  width: 100%;
  border-top: 1px solid #969dac;
  margin: 6px 9px;
  margin-top: 11px;
}
.fic-tickets-data {
  border-bottom: 1px solid #969dac;
  padding-bottom: 15px;
  padding-top: 15px;
}

.flight-retrive-value {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px 0;
  justify-content: space-between;
  font-weight: 600;
  font-size: 12px;
}
.plane-retrive-data {
  display: flex;
}

.plane-retrive-data ul {
  display: flex;
  align-items: center;
}

.plane-retrive-data ul li {
  margin-right: 10px;
  .anticon {
    display: block;
  }
}
.plane-retrive-data ul li p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
}
.travel-time-data-info {
  display: flex;
  align-items: center;
  margin-left: 80px;
}

.travel-time-data-info p {
  margin-bottom: 0;
  margin-left: 5px;
  display: flex;
  align-items: center;
  color: #ccc;
}
.infomail-time-data-info {
  display: flex;
  align-items: center;
}
.infomail-time-data-info p {
  margin-bottom: 0;
  margin-left: 5px;
  display: flex;
  align-items: center;
  color: #000000;
}

.details-fli-one {
  display: flex;
  align-items: center;
  margin-left: 40px;
}
.details-fli-one p {
  margin-bottom: 0;
  margin-left: 5px;
  display: flex;
  align-items: center;
  color: #078bea;
  cursor: pointer;
}

/* second--col */
.paymet-booking-tic h4 {
  color: #d5436a;
  margin-left: 35px;
  font-weight: 600;
  font-size: 16px;
}

.paymet-booking-tic p {
  margin-left: 35px;
  font-weight: 600;
  font-size: 14px;
}
.tic-retrive-book {
  width: 100%;
  display: flex;
  text-align: center;
  line-height: 20px;
  padding: 0px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 700;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  position: relative;
  overflow: hidden;
  z-index: 2;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  transition: background-color 0.2s linear 0s;
  color: #425486;
  background-color: transparent;
  border: 1px solid #a1a9c3;
  height: 48px;
  font-size: 14px;
  background-color: #d5436a !important;
  color: white !important;
  border-color: #d5436a !important;
  margin-left: 35px;
}
@media screen and (max-width: 768px) {
  .details-fli-one {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .travel-time-data-info {
    display: flex;
    align-items: center;
    margin-left: 0px;
  }

  .infomail-time-data-info {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .paymet-booking-tic h4 {
    margin-left: 0px;
    font-weight: 600;
    font-size: 19px;
  }

  .paymet-booking-tic p {
    margin-left: 0px;
    font-weight: 600;
    font-size: 16px;
  }
  .tic-retrive-book {
    margin-left: 0px;
  }
}
.flight-email-dd {
  margin-top: 55px;
}
