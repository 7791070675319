.managebalence-btns {
    text-align: center;
    margin-bottom: 15px;
    button {
        padding: 10px 15px;
        margin-right: 5px;
        border-radius: 5px;
        border: none;
    }
}
.amountclass{
    color:red;
}
.manage-modal-sheet{
    width: 800px !important;
}