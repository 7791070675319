@import "../../assets/variableStyles.scss";

.retrieve-booking {
  .ticket-lti-bus {
    color: #d5436a;
    font-size: 18px;
    font-weight: 700;
  }
  .round-bus-tic {
    margin-top: 20px;
    font-weight: 700;
    color: #d5436a !important;
  }
  .fic-tickets-data-bus {
    border-bottom: 1px solid #969dac;
    padding-bottom: 15px;
    .bd-bus-info {
      border-bottom: 1px solid #969dac;
      padding-bottom: 6px;
      margin-bottom: 10px;
      .travel-bus {
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: 600;
      }
      .subtitle {
        font-size: 12px;
        margin-bottom: 0px;
      }

      .airline-dte-tic-bus-add-value,
      .tic-indi-details-bus {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          font-size: 14px;
          color: #000000;
          font-weight: 600;
        }
        .airline-tic-files-bus {
          font-size: 12px;
          width: 100%;
          border-top: 1px solid #969dac;
          margin: 6px 9px;
          margin-top: 11px;
        }
      }
    }
    .bus-success-pay {
      width: 55px;
    }
    .airline-dte-tic-bus {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      color: #000000;
      font-weight: 600;
      p {
        margin-bottom: 0;
      }
    }
    .pnr {
      margin-bottom: 0px;
      font-size: 12px;
      font-weight: 600;
      text-align: right;
      @include mobile {
        text-align: left;
      }
    }
  }
  .flight-email-dd-bus {
    margin-top: 52px;
    @include mobile {
      margin-top: 10px;
    }
    .tic-retrive-book {
      width: 100%;
      border-radius: 8px;
      font-weight: 700;
      height: 48px;
      background-color: #d5436a !important;
      color: white !important;
      border-color: #d5436a !important;
    }
    .paymet-booking-tic {
      h4 {
        font-weight: 600;
        font-size: 18px;
        color: #d5436a;
      }
      p {
        font-size: 14px;
      }
    }
  }
}
