@import "../../../assets/variableStyles.scss";
.hotels_results_page {
  @include mobile {
    .hide-icon-heading {
      display: block !important;
    }

    .accordion {
      margin-top: 40px;
      @include mobile {
        margin-top: 0;
      }
      .card {
        border: none;

        h2 {
          background: url(../../../assets/images/down-arrow-1.png) no-repeat
            calc(100% - 10px) center;
          background-size: 20px;
          cursor: pointer;
          font-size: 18px;
          color: #ffffff;
          &.collapsed {
            background-image: url(../../../assets/images/up-arrow-2.png);
          }
        }
        &-body {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  .hotels-page-wrapper {
    .hotels_modify {
      //background-color: #00164d;
      height: 100%;
      width: 100%;

      background-color: #2f81ed;
      background-image: linear-gradient(to right, #2f80ed, #56ccf2);
      .bread-crumb-block {
        padding-top: 67px;
        ul.breadcrumb {
          padding: 10px 16px;
          list-style: none;
          background-color: transparent;
        }
        ul.breadcrumb li {
          display: inline;
          font-size: 12px;
        }
        ul.breadcrumb li + li:before {
          padding: 8px;
          color: #dcdee3;
          content: "/\00a0";
        }
        ul.breadcrumb li a {
          color: #dcdee3;
          text-decoration: none;
        }
        ul.breadcrumb li a:hover {
          color: #dcdee3;
          text-decoration: underline;
        }
        ul.breadcrumb li span {
          color: #fff;
          font-weight: 700;
        }
      }
      .hotel-results-text {
        h2 {
          color: #fff;
          font-size: 34px;
          text-align: center;
          .hotel-results-to-text {
            font-weight: 700;
          }
        }
      }
      // .search-modify-block {
      //   padding-bottom: 20px;
      // }
      .search-modify-block > .hotels_search_box {
        background-color: transparent;
        .hotels-body {
          .custom-column {
            label {
              color: #bcd5f5;
            }
          }
        }
      }
    }
    .hotels_list {
      background: transparent;
      .hotels-list-wrapper {
        max-width: 1200px;
        margin: 0 auto;
        padding: 15px;
        .list-container {
          display: flex;
          .filters-box {
            width: 24%;
            flex-grow: 0;
            flex-shrink: 0;
            padding: 0 10px;
            @media screen and (max-width: 768px) {
              display: none;
            }
            .ant-card-body {
              padding: 15px;
              .ant-skeleton {
                .ant-skeleton-title {
                  width: 100% !important;
                }
              }
            }
          }
          .hotels-box {
            width: 77%;
            flex-grow: 0;
            flex-shrink: 0;
            padding: 0 0px;

            .card-skeleton {
              .ant-card-body {
                padding: 0 15px;
              }
              margin-bottom: 12px;
            }
            .showmore-wrapper {
              text-align: center;
            }
            .hotels-sorting-block {
              .results-sort-block {
                display: flex;
                align-items: center;
                padding: 15px 0;
                .sort-header {
                  color: #505c75;
                  font-size: 15px;
                  font-weight: 600;
                  margin-bottom: 0;
                }
                .sort-body {
                  display: flex;
                  align-items: center;
                  margin-bottom: 0;
                  li {
                    margin: 0 10px;
                    a {
                      font-size: 16px;
                      color: #969dac;
                      &:hover {
                        color: #1c2b39;
                        text-decoration: none;
                      }
                    }
                  }
                  .active {
                    a {
                      color: #1c2b39;
                      border-top: 2px solid #0775e2;
                      padding: 5px 0;
                    }
                  }
                }
              }
              @media screen and (max-width: 768px) {
                display: none;
              }
            }
            @media screen and (max-width: 768px) {
              width: 100%;
              padding: 0 15px;
            }
          }
        }
      }
    }
  }
}

.hotel-images-modal {
  .modal-wrapper {
    padding: 20px 30px 20px 20px;
    background-color: #00164d;
    .modal-title {
      display: flex;
      align-items: center;
      p {
        color: #fff;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 0;
      }
      sup.hotel-star-rating {
        top: 0;
        margin-left: 5px;
        li {
          margin-right: 0px !important;
        }
      }
    }
    button {
      padding: 0.5rem 1rem;
      span {
        font-size: 2.5rem;
        color: #bcd5f5;
        text-shadow: 0 1px 0 #bcd5f5;
      }
    }
  }
}
.hotel-modify-container {
  max-width: 1100px;
  padding: 15px;
  margin: auto;

  .ant-collapse {
    background: transparent;
    border: none;
    .ant-collapse-item {
      border: none;
      text-align: right;
    }
    .ant-collapse-header {
      padding: 0;
      display: none;
      @include mobile {
        display: inline-block;
      }
    }
    .ant-collapse-content {
      text-align: left;
      border: none;
      background: transparent;
      .ant-collapse-content-box {
        padding: 0;
        .hotels_search_box {
          background: transparent;
          padding: 0;
        }
      }
    }
  }
  .ant-form-item {
    flex-direction: column;
  }
  .ant-form-item-label {
    label {
      color: #bcd5f5 !important;
    }
  }
  .hotels_search_box {
    padding: 90px 15px 20px;
  }
  .ant-form-item-control {
    width: 100%;
  }
}
/*********** Skeleton Styles ***********/
.hotel-skeleton-layout {
  background-color: transparent;
  .card-skeleton {
    margin-bottom: 15px;
    .ant-card-body {
      padding: 0 15px;
    }
  }
  .hotel-skeleton-layout-container {
    max-width: 1200px;
    padding: 30px;
    margin: auto;
  }
  .sort-skeleton {
    .ant-skeleton-title {
      width: 60% !important;
      height: 25px !important;
    }
  }
}

/*********** modal--start ***********/

.hotel-center-cls {
  text-align: center;
  display: inline-flex;
  margin: 0px 3px;
  background: #000;
  padding: 0px 8px;
  border-radius: 3px;
}

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Left*/
.modal.left.fade .modal-dialog {
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog {
  left: 0;
}

.demo {
  padding-top: 60px;
  padding-bottom: 110px;
}

.btn-demo {
  margin: 15px;
  padding: 10px 15px;
  border-radius: 0;
  font-size: 16px;
  color: #ffffff !important;
}

.btn-demo:focus {
  outline: 0;
}

.demo-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px;
  background-color: #212121;
  text-align: center;
}

.demo-footer > a {
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
}
.hotel-modal-filters {
  display: block !important;
}
.fiters-value-hotel {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .fiters-value-hotel {
    display: inline !important;
    position: fixed;
    left: 0;
    bottom: -1px;
    right: 0;
    z-index: 2;
    //background: #ef6614;
    text-align: center;
  }
  .demo-filters-action {
    background: #000 !important;
    color: #ffffff !important;
    /* padding: 10px 10px !important; */
    margin: 0px 3px;
    /* background: #ef6614; */
    padding: 8px 20px !important;
  }
  .hotel-filters-jun {
    text-align: center;
    display: inline;
  }
  .hotel-sort-by {
    padding-top: 27px;
    font-size: 1rem;
    color: #ffffff;
  }

  .sortby-ul li {
    float: none;
    text-align: left;
    padding-bottom: 18px;
  }

  .sortby-ul li a {
    color: #000000;
  }

  .hotels_search_list
    .results-wrapper
    .hotel-title
    .hotel-title-wrapper
    .hotel-star-rating
    li {
    margin-right: 0px !important;
  }
  .gif-icon {
    margin: 0 auto;
    display: block;
  }
  .offers-hotel {
    text-align: center;
    color: #e2076a;
    font-size: 18px;
    padding-top: 20px;
  }
}

/* dec-12-2020 */
.filters-box .ant-card-bordered {
  border-radius: 8px;
}
.hotels-hide-search {
  margin: 10px 0;
  float: right;
  cursor: pointer;
  color: #000000 !important;
  background: #ffffff !important;
  padding: 5px 5px;
  border-radius: 5px;
  font-size: 13px !important;
}

.search-modify-block .hotels_search_box {
  padding: 0px 0px 0px !important;
}
