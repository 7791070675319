.thankyou-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;

  .ant-card {
    width: 800px;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgb(19, 41, 104 / 20%);
  }
  h3 {
    text-align: center;
    font-weight: bold;
  }
  h6 {
    font-weight: 600;
  }
  p {
    margin-bottom: 0px;
  }
  .modalIcon-v3 {
    font-size: 58px;
    margin-bottom: 20px;
    color: #1cad61;
  }
  .sucessModal-v3 {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.socialLinks-v3 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 0;
  span {
    width: 60px;
    color: #9a9a9a;
  }
}
.img-thanku {
  display: block;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .thankyou-panel .ant-card {
    width: 100% !important;
  }
}
