.buildpackage-searchbox {
  .coming-soon {
    text-align: center;
  }
}

.build-pk-btn-top {
  background-color: #e2076a !important;
  // border-color: #fff;
  color: #fff !important;
  width: 100%;
  height: 38px;
  margin-top: 30px;
}
.build-pk-btn {
  background-color: #ea1b37 !important;
  border-color: #fff;
  color: #fff !important;
  width: 100%;
  height: 38px;
}
.build-select-btn {
  background-color: #e2076a !important;
  border-color: #fff;
  color: #fff !important;

  height: 38px;
}
.build-pk-btn-top {
  background: #ea1b37 !important;
  border-color: #fff;
  color: #fff !important;
  margin-top: 20px;
  height: 38px;
  width: 100%;
}
.build-cols {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.biuild-input {
  height: 66px;
  width: 100%;
  border: 0px solid !important;
}
.biuild-input .ant-select-selector {
  height: 66px !important;
  border-color: transparent !important;
  border: 0px solid !important;
  border-right-width: 0px !important;
  font-weight: bold;
}
.biuild-input .ant-select-selection-placeholder {
  line-height: 65px !important;
}

.biuild-input
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none !important;
}
.ha-build {
  background: #f1f2f6;
  padding: 0 10px;
  color: #585858;
  height: 29px;
  border-radius: 20px;
  margin: 0px 5px;
  margin-top: 16px;
}
.label-bd-pkg span {
  line-height: 23px;
}
.label-bd-pkg .ant-checkbox {
  padding-left: 7px;
}
.one-pkg-data-1 {
  border-right: 1px solid #000 !important;
  height: 66px;
}
.one-pkg-data-2 {
  border-right: 1px solid #000 !important;
  height: 66px;
}

.one-pkg-data-2 .ant-picker:hover,
.ant-picker-focused {
  border-color: transparent !important;
  border-right-width: 0px !important;
}
.build-cols label {
  font-size: 11px;
  font-weight: 700;
  color: #1c2b39;
  display: none;
}
@media screen and (max-width: 768px) {
  .build-pk-btn-top {
    margin-top: 0px;
  }
  .cp-build-search {
    margin-top: 10px;
  }
  .ha-build {
    margin: 0px 0px !important;
    margin-top: 15px !important;
  }
}
.inner-build-btn {
  height: 67px;
  background: #ea1b37;
  text-align: center;
  display: block;
  color: #fff;
  font-size: 20px;
  outline: 0;
  border: 0;
  font-weight: 600;
  margin: 0px 7px;
}
.build-alert-sec .ant-form-item-explain.ant-form-item-explain-error {
  color: #fff !important;
  font-weight: 500;
}
