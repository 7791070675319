.cart-img {
  width: 256px;
  height: 188px;
}
.cart-shop {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.cart-shop h4 {
  font-size: 20px;
  font-weight: bold;
}
.cart-shopping-img {
  width: 90%;
  height: 100%;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 5px;
}
.cart-shopping-img:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.08);
}
.cart-h4 {
  position: absolute;
  top: 48%;
  left: 26%;
  color: #fff;
  font-weight: bold;
}
.shopping-heading {
  font-size: 20px;
  font-weight: bold;
  color: #424242;
}
.second-row-img {
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .cart-shopping-img {
    padding-bottom: 15px;
  }
}
