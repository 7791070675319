@import "../../../assets/variableStyles.scss";
.transport-container-block {
    max-width: 1280px;
    margin: auto;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 15px;
    @include mobile {
        margin-top: 65px;
    }
}
.results-found-text {
    color: #000;
    font-size: 20px;
}
.filters-block {
    display: block;
    @include mobile {
        display: none;
    }
}