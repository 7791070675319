.hotel-tickets-data {
  .hotel-info {
    .name,
    .title {
      font-size: 14px;
      font-weight: 600;
    }
    .name {
      margin-bottom: 0;
    }
    .subtitle {
      font-size: 12px;
    }
  }
}
