@import "../../../assets//variableStyles.scss";
.hotel-preview-container {
  .form-body {
    background-color: #eaebee;
    .form-body-container {
      max-width: 1100px;
      padding: 15px;
      margin: auto;
      .hotel-card-wrapper {
        border-radius: 8px;
        box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
        margin: 15px 0;
        padding: 16px;
        .ant-card-body {
          padding: 0;
        }
      }
    }
    .book-pay-card {
      padding: 16px 24px;
      box-shadow: 0 2px 2px 0 #dcdee3;
      border: none;
      @include mobile {
        padding: 16px;
      }
      .book-pay-tc {
        display: flex;
        span {
          color: red;
        }
        p {
          margin-bottom: 0;
        }
      }
      .book-pay-offers {
        display: flex;
        p {
          font-size: 15px;
          a {
            &:hover {
              color: #007bff;
            }
          }
        }
      }
      .book-pay-btn {
        button {
          background-color: #ea1b37;
          color: #fff;
          border: none;
          font-size: 17px;
          font-weight: 600;
          padding: 8px 17px;
          height: 100%;
          @include mobile {
            font-size: 16px;
            padding: 10px 15px;
          }
        }
        .book-and-content {
          padding-top: 20px;
          p {
            font-size: 15px;
            color: #969dac;
          }
        }
      }
    }
  }
}
