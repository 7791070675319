
.activities-section
{
    .Activity-bg{

            min-height: 300px;
            .coming-soon
            {
                text-align: center;
            }
    }
}