@import "../../../assets/variableStyles.scss";
.list-view-wrapper {
  .more-background {
    background-color: #fff !important;
    border: solid 1px #ccc;
    margin-top: 20px;
    margin-bottom: 10px;
    &:hover {
      cursor: pointer;
      background-color: #f5f8fd !important;
    }
  }
  .deal-list-item {
    background-color: #eaf2fc;
    border: 1px solid #dcdee3;
    border-radius: 3px;
    margin: 10px 0px;
    .deal-list-img {
      img {
        width: 180px;
        height: 100px;
      }
    }
    .more-deal-list-img {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 16px;
      img {
        width: auto;
        height: auto;
      }
    }
    .list-item-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 20px;
      @include mobile {
        flex-direction: column;
        padding: 10px;
      }
      .item-left-text {
        h3,
        p {
          margin-bottom: 0px !important;
        }
        h3 {
          font-size: 24px;
          color: #1c2b39;
          @include mobile {
            font-size: 16px;
          }
        }
        p {
          font-size: 14px;
          color: #969dac;
        }
        @include mobile {
          width: 100%;
        }
      }
      .item-right-text {
        display: flex;
        align-items: center;
        padding-right: 20px;
        @include mobile {
          padding: 0;
          width: 100%;
        }
        .more-item-price {
          color: #0775e2;
          font-weight: 700;
          .price-prefix {
            font-size: 14px;
            @include mobile {
              font-size: 12px;
            }
          }
          .price {
            font-weight: 700;
            color: #0775e2;
            font-size: 20px;
            padding: 0px 5px;
          }
        }
        .item-price {
          padding-right: 50px;
          @include mobile {
            padding: 0;
            width: 100%;
            text-align: center;
          }
          .price-prefix {
            font-size: 14px;
            @include mobile {
              font-size: 12px;
            }
          }
          .price {
            font-weight: 700;
            color: #1c2b39;
            font-size: 25px;
            padding: 0px 10px;
            @include mobile {
              font-size: 17px;
              padding: 0 5px;
            }
          }
          .price-suffix {
          }
        }
        .deals-btn {
          button {
            color: #fff;
            background-color: #0775e2;
            border: none;
            border-radius: 3px;
            font-size: 14px;
            font-weight: 600;
            height: 47px;
            letter-spacing: 1px;
            display: flex;
            align-items: center;
            @include mobile {
              font-size: 12px;
              height: 35px;
            }
          }
          i {
            font-size: 17px;
            font-weight: 700;
            padding-left: 5px;
          }
        }
      }
    }
  }
}
.deals-list-container {
  margin-bottom: 5px;
}
.show-more-btn {
  text-align: center;
  margin-top: 50px;
  button {
    padding: 15px 30px;
    color: #0775e2;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid #bcd5f5;
    background-color: #f5f8fd;
    i {
      margin-left: 10px;
    }
  }
}
.scroll-to-top {
  margin-top: 20px;
  text-align: center;
  p {
    font-size: 14px;
    font-weight: bold;
    padding: 0 30px;
    color: #0775e2;
  }
}
