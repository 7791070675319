@import "../../assets/variableStyles.scss";
.add-deposit-icon {
  font-size: 30px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.failure {
  height: 50px;
  background-color: (#d66565);
  color: rgb(211, 9, 9);
  font-size: 15px;
  padding: 5px;
}
.success {
  height: 50px;
  background-color: (#9bcef8);
  color: rgb(46, 33, 230);
  font-size: 15px;
  padding: 5px;
}
.successModal {
  width: 800px !important;
}
.successModal .ant-modal-header {
  background: transparent important;
}
.successModal .ant-modal-close-x {
  color: #000 !important;
}
.cancel-btn {
  margin-left: 10px;
}
.upload-files {
  text-align: end;
  margin-bottom: 24px;
  @include mobile {
    text-align: start;
  }
}
.payment-details-block {
  margin-bottom: 15px;
  margin-left: 33%;
  @include mobile {
    margin-left: 0;
  }
  h5 {
    font-size: 16px;
    font-weight: 600;
  }
  ul {
    li {
      display: flex;
      justify-content: space-between;
      p {
        margin-bottom: 0;
      }
    }
  }
}
@include mobile {
  .certify-text {
    .ant-form-item-control {
      margin-left: auto;
    }
  }
}
.deposits-popup-sec {
  width: 800px !important;
}
.last-submit-depoit {
  text-align: end;
}
.card-deposit-preview {
  height: 58px !important;
}
.deposits-offset {
  margin-left: 0% !important;
}
.deposit-modal-popup {
  width: 800px !important;
}
.instant-rechare-21 .ant-form-item {
  margin-bottom: 0px;
}
