@import "../../assets/variableStyles.scss";

.main_div {
  text-align: left;
  height: auto;
  padding: 24px 0px 20px;
  justify-content: center;

  height: auto;
  padding: 0px;
  .hide-icon-heading-two {
    display: block !important;
  }

  .accordion {
    // margin-top: 40px;
    // @include mobile {
    //   margin-top: 0;
    // }
    .card {
      border: none;
      // margin-bottom: 20px;
      h2 {
        /*   background: url(../../assets/images/down-arrow-1.png) no-repeat
            calc(100% - 10px) center; */
        background-size: 20px;
        cursor: pointer;
        font-size: 18px;
        color: #000000;
        float: right;
        background: #ffffff;
        padding: 5px 10px;
        border-radius: 6px;
        /*  &.collapsed {
            background-image: url(../../assets/images/up-arrow-2.png);
            background-size: 20px;
          } */
      }
      &-body {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

.result_div {
  padding: 20px 0 !important;
  @include mobile {
    padding: 10px !important;
  }
  background-color: #ffffff;
  .result_div-container {
    max-width: 1200px;
    padding: 0 0px;
    margin: auto;
    .flights-nearby-dates-container {
      margin-bottom: 15px;
    }
    .flight-sort-container {
      margin-bottom: 15px;
    }
  }
}
.modify-search-container {
  //background-color: #00164d;
  background-color: #2f81ed;
  background-image: linear-gradient(to right, #2f80ed, #56ccf2);
  padding-top: 0px;
  padding-bottom: 10px;
  .modify-search-container-fluid {
    max-width: 1200px;
    padding: 0 0px;
    margin: auto;

    .lowest-ne-p {
      display: none;
    }
    .ant-collapse {
      background: transparent;
      border: none;
      .ant-collapse-item {
        border: none;
        text-align: right;
      }
      .ant-collapse-header {
        padding: 0;
        display: none;
        @include mobile {
          display: inline-block;
        }
      }
      .ant-collapse-content {
        text-align: left;
        border: none;
        background: transparent;
        .ant-collapse-content-box {
          padding: 0;
          .hotels_search_box {
            background: transparent;
            padding: 0;
          }
        }
      }
    }
    .ant-form-item {
      flex-direction: column;
    }
    .ant-form-item-label {
      label {
        color: #bcd5f5 !important;
      }
    }
    .hotels_search_box {
      padding: 90px 15px 20px;
    }
    .ant-form-item-control {
      width: 100%;
    }
  }
}

/* p{ 
    margin-left:110px; 
} */

.filter-section {
  @include mobile {
    display: none;
  }
}
/******* modify search ******/
.modify-search-container {
  .hotels_search_box {
    width: 100%;
    background-color: transparent;
    padding: 0;
    border-radius: 0;
  }
  .class-container {
    display: none;
  }
  .ant-form-item-label {
    label {
      color: #bcd5f5 !important;
    }
  }
  .modify-class-type-selector {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
  }
  .modify-selectors {
    .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
      color: #fff;
      text-align: start;
      padding-left: 0 !important;
      .ant-select-selection-item {
        &:focus {
          border: none !important;
        }
      }
    }
    .ant-select-arrow {
      color: #fff !important;
    }
  }
  .class-type {
    color: #fff !important;
    margin-right: 10px;
  }
}
/********* Skeleton Styles ************/
.flight-results-skeleton-container {
  max-width: 1200px;
  padding: 0 0px;
  margin: auto auto 15px;
  // padding: 0;
  .flight-result-container {
    margin-bottom: 25px;
    border: 1px solid rgba(0, 0, 0, 0.03);
    box-shadow: 0px 0px 2px 0px #969dac;
  }
  .flights-skeleton-card {
    margin-bottom: 15px;
    .nearbydates-skeleton {
      background-color: #fff;
      margin-bottom: 15px;
      .ant-skeleton-title {
        margin-left: 35px;
        width: 20% !important;
      }
    }
    .flights-sort-skeleton {
      height: auto;
      background-color: #fff;
      margin-bottom: 15px;
      // height: 70px;
    }
    .flight-sort-skeleton-row {
      padding: 0 15px 10px;
      @include mobile {
        padding: 0 10px 10px 10px;
      }
      .airline-name,
      .sort-name {
        .ant-skeleton-title {
          width: 55% !important;
        }
      }
    }
    .flight-result-card {
      .flight-result-row {
        .flight-details {
          padding: 15px;
          background-color: #fff;
          .cities-between {
            .ant-skeleton-title {
              width: 70% !important;
              margin-left: 87px;
              height: 25px;
            }
          }
          .stops-class {
            .ant-skeleton-content {
              .ant-skeleton-title {
                margin-left: auto;
                margin-top: 0;
              }
              .ant-skeleton-paragraph {
                li {
                  margin-left: auto;
                }
              }
            }
          }
          .time-details {
            margin-left: auto;
            .ant-skeleton-title {
              width: 100% !important;
              margin-top: 0;
              height: 25px;
            }
          }
        }
      }
    }
    .choose-block-container {
      background: #fff;
      display: flex;
      align-items: center;
      border-left: 1px solid #e8e8e8;
      @include mobile {
        display: none;
      }
      .ant-skeleton-title {
        width: 55% !important;
        margin: 20px auto 15px !important;
        height: 25px;
      }
      .ant-skeleton-paragraph {
        li {
          &:nth-child(1) {
            width: 55%;
            margin: auto;
            height: 20px;
          }
          &:nth-child(2) {
            width: 40%;
            margin: 10px auto;
            height: 30px;
          }
          &:nth-child(3) {
            width: 35% !important;
            margin: 10px auto 25px;
            height: 25px;
          }
        }
      }
    }
  }
}

.fiters-value-hotel-1 {
  display: none !important;
}
@media screen and (max-width: 768px) {
  .fiters-value-hotel-1 {
    display: block !important;
    position: fixed !important;
    left: 0;
    bottom: -1px;
    right: 0;
    z-index: 2;
    //background: #ef6614;
    text-align: center;
  }
  .hotel-sort-by-1 {
    padding-top: 16px;
    font-size: 1rem;
    color: #ffffff;
    margin-bottom: 0px;
    padding: 6px 0px;
  }

  .radio-sort {
    border: 2px solid #e2076a;
    padding: 10px !important;
    border-radius: 5px;
    align-items: center;
  }
  .main_div {
    padding: 15px;
  }
  .sort-modal-mobiletr .sort-block {
    display: block !important;
  }
}

/* dec-10-2020 */
.main_div .collapsed {
  display: block;
  margin-left: auto;
  float: right;
}

.mobile-accordi {
  h2 {
    float: right;
    color: #000000;
    background: #ffffff !important;
    padding: 5px 5px;
    border-radius: 5px;
    font-size: 16px !important;
    margin-bottom: 5px !important;
  }

  .plus-icon,
  .minus_multicity {
    color: #fff;
  }
}

.filter-section .ant-card-bordered {
  border-radius: 8px;
}
.flight-arrow-icon .arrow-flight-inr {
  display: none;
}

.flight-arrow-icon .lowest-ne-p {
  display: none;
}
.fiters-value-hotel-1 {
  display: none;
}
