.otp-modal {
    p {
        text-align: center;
        margin-bottom: 25px;
        font-size: 18px;
        margin-top: 25px;
    }
    .otp-input {
        margin-bottom: 25px;
    }
    .otp-continue-btn {
        text-align: center;
    }
    .ant-modal-close-x {
        color: #000 !important;
        font-size: 18px;
    }
}