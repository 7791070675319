.slider-img-holidays {
  width: 100% !important;
  max-height: 460px;
}
.holidays-near {
  margin-top: 30px;
  font-family: "GT Eesti Pro Display", Arial, sans-serif !important;
}
.price-gua {
  background: transparent !important;
  color: #000000 !important;
  border-color: transparent !important;
  padding: 0px 0px !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0px 0 rgba(0, 0, 0, 0.035) !important;
}
.price-gua:focus,
.price-gua:hover {
  border-color: transparent !important;
}
.ant-popover-inner-content {
  width: 251px;
}
.all-near-places-1 {
  font-size: 18px !important;
  font-weight: 500 !important;
  padding: 8px 20px !important;
  background-color: white !important;
  border: solid 1px #e2076a !important;
  color: #e2076a !important;
  border-radius: 2px !important;
  height: auto !important;
  width: 100%;
}

.all-near-places-1:hover {
  background-color: #e2076a !important;
  color: #ffffff !important;
}
.review-holi li {
  float: left;
  padding: 10px 10px;
}
.star-rating {
  font-size: 16px;
  color: #ffa628;
}
.border-top-line {
  border-top: 1px solid #eee;
}
.border-top-line-1 {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.cancel-no-policY {
  padding-top: 20px;
}
.cancel-no-policY li {
  float: left;
  padding: 10px 43px;
}
.largest-value li {
  padding: 10px;
}
.act_booking_title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.3;
  color: #333333;
}
.package-type {
  padding-top: 38px;
}
.select-date-option {
  background-color: #f0f6f9 !important;
}
.act_unit__name {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #333;
}
.act_unit {
  line-height: 15px;
  font-size: 12px;
  color: #ffa628;
}
.rating-number {
  display: flex;
}
.market_price {
  line-height: 21px;
  font-size: 12px;
  color: #999999;
  text-decoration: line-through;
  margin-top: 10px;
}
.selling_price {
  margin-left: 8px;
  line-height: 21px;
  font-size: 16px;
  font-weight: 600;
  color: #ff5722;
  margin-top: 10px;
}
.pkg-terms li {
  padding-bottom: 10px;
}
.side_package__keywords {
  padding: 20px;
  border-radius: 2px;
  background-color: #fff;
}
.keywords-insta li {
  font-size: 14px;
  line-height: 20px;
  color: #333;
  padding-bottom: 10px;
}
.side_package__main {
  position: relative;
  margin-top: 16px;
  padding-bottom: 50px;
  max-height: calc(
    100vh - 110px - 20px - 80px - 20px - 40px - 20px - 26px - 30px
  );
  max-height: 220px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
  overflow-y: auto;
}
.datail-pkg::before {
  content: "";
  height: 16px;
  width: 4px;
  background: #e64340;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.datail-pkg {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #333333;
  position: relative;
  padding-left: 14px;
}
.trip-uses li {
  margin-bottom: 8px !important;
}
.booking-option {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: #424242;
  padding-left: 16px;
  padding-top: 10px;
}
.booking-option::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 5px;
  height: 20px;
  background-color: #ff5722;
  border-radius: 5px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.option-sec {
  margin-top: 20px;
}
.child-person {
  margin-top: 25px;
}
.div--price_total {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  color: #ff5722;
}
.cart-btn {
  display: flex;
}
.addtocart {
  justify-content: center;
  padding: 0 20px;
  font-weight: 500;
  color: #fff;
  line-height: 22px;
  font-size: 18px;
  border-radius: 2px;
  background-color: #ffa628;
  height: 48px;
}
.book-cart {
  justify-content: center;
  padding: 0 20px;
  font-weight: 500;
  color: #fff;
  line-height: 22px;
  font-size: 18px;
  border-radius: 2px;
  background-color: #ff5722;
  height: 48px;
}
.img-boottom-para {
  margin-top: 15px;
}
.ramoji-film-city-maps {
  width: 100%;
  height: 350px;
}
.start-review-rating li {
  float: left;
  padding: 0px 7px;
  font-size: 40px;
  font-weight: bold;
  color: #424242;
}
.review-count {
  font-size: 18px !important;
  line-height: 73px;
  color: #666;
}
.start-review-rating li .fa {
  color: #ffa628;
  font-size: 23px;
}
.gallery-wrapper {
  margin-top: 30px;

  padding: 16px;

  background-color: #e4f3fd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.gallery-wrapper .gallery-desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.slider-img-holidays-1 {
  width: 120px;
  height: 120px;
  border-radius: 4px;
  cursor: pointer;
}
.view-more-photos {
  text-align: right;
  margin-bottom: 20px;
}
.forms-rating {
  margin-top: 20px;
}
.five-star {
  color: #ffa628;
  padding: 0px 4px;
}
.rating-button {
  text-align: right;
}
.star-btn-one {
  color: #fff;
  background-color: #ff5722;
  height: 32px;
  font-size: 14px;
  line-height: 16px;
  padding: 0px 20px;
}
.form-padding {
  padding: 0px 10px;
}
.review-sort {
  display: flex !important;
}
.persons-rating {
  display: flex;
}
.persons-rating p {
  padding: 0px 5px;
}
.help-ful-btn {
  margin-top: 25px;
}
.review-help-center {
  background-color: #ffffff;
  padding: 15px;
}
.date-of-review {
  text-align: right;
}
.recent-review-module {
  padding: 40px 0 32px;
  border-bottom: 1px solid #e0e0e0;
}
.recent-review-module-1 {
  border-bottom: 1px solid #e0e0e0;
}
.center-line {
  margin-bottom: 20px;
}
.ft-top {
  margin-top: 25px;
}
.places-promo-btn {
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.12);
}
.credits-p {
  padding-top: 20px;
}
.bread-cubs {
  margin-top: 20px;
}
.groupfrom .form-group {
  margin-bottom: 0rem !important;
}

/*css--09-2021*/
.cms-pages-width-places {
  width: 1170px;
}
.new-sec-1key {
  border: 1px solid;
  border-color: #e2e2e2 #e2e2e2 #1fa1d3 #e2e2e2;
  background-color: #fff !important;
  padding: 0px 20px;
  padding-bottom: 25px;
  padding-top: 25px;
}
.key-option {
  height: 40px;
  display: table;
}
.key-option .tour-favi {
  font-size: 20px;
  color: #00295d;
  background: #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 3px;
  box-shadow: 0 0 6px 1px #888;
  text-align: center;
  float: left;
}
.aling-middle {
  line-height: 40px;
  padding-left: 15px;
}
.key-row-2 {
  padding-top: 25px;
}
.des-row-key {
  margin-top: 25px;
}
.deatils-key {
  font-size: 18px;
}
.inclu li:before {
  color: #98ce44;
  font-family: FontAwesome;
  margin-right: 8px;
  background: 0 0;
  content: "\f00c";
}
.inclu li {
  font-size: 14.5px;
}
.importinfo li {
  font-size: 14.5px;
  list-style: inside;
}
@media screen and (max-width: 768px) {
  .cms-pages-width-places {
    width: 100% !important;
  }
}
.book-cart-all {
  justify-content: center;
  padding: 0 20px;
  font-weight: 500;
  color: #fff;
  line-height: 22px;
  font-size: 18px;
  border-radius: 2px;
  background-color: #ea1b37;
  height: 38px;
  line-height: 38px;
  float: right;
  border-radius: 5px;
}
.emailflyer {
  text-align: right;
}
.flyerbtn-1 {
  background: #5cbe4d !important;
  color: #ffffff;
  height: 28px;
  padding: 0 24px;
  line-height: 28px;
  font-size: 14px;
}

.flyerbtn-2 {
  background: #0098c9;
  height: 28px;
  padding: 0 24px;
  line-height: 28px;
  font-size: 14px;
  color: #ffffff;
}
