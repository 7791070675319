$primary: #ea1b37;
$secondry: #05004e;
.unauthorized {
  .ant-result-icon {
    display: block;
  }
  img {
    max-width: 250px;
  }
  .ant-result-icon {
    margin-bottom: 0;
  }
  .ant-result-title {
    color: #ff0303;
    font-weight: 800;
    font-size: 42px;
  }
  .ant-result-subtitle {
    font-weight: 600;
  }
  .ant-btn {
    background-color: $primary !important;
    border-color: $primary !important;
    color: #fff;
    width: 100%;
    max-width: 250px;
    font-weight: 600;
    &:hover {
      background: $secondry !important;
      background-color: $secondry !important;
    }
  }
}
.error-404 {
  .ant-btn {
    background-color: $primary !important;
    border-color: $primary !important;
    color: #fff;
    width: 100%;
    max-width: 250px;
    font-weight: 600;
    &:hover {
      background: $secondry !important;
      background-color: $secondry !important;
    }
  }
}
