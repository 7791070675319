.activi-hotelinfo {
  font-size: 15px;
  font-weight: 600;
}
.quotation-btn {
  font-size: 16px !important;
  font-weight: 500 !important;
  background-color: white !important;
  border: solid 1px #ea1b37 !important;
  color: #ea1b37 !important;
  border-radius: 2px !important;
  height: auto !important;
}
.quotation-card {
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 #13296833;
}
.hotel-table-name {
  background: #ffffff;
  padding: 6px 6px;
  border: #f5f5f5 1px solid;
  margin-top: 13px;
  box-shadow: 0 2px 2px 0 #13296833;
}
.hotel-table-name p {
  margin-bottom: 0px;
  font-weight: bold;
}
.hotel-table-name span {
  font-size: 12px;
}
.guestpersons {
  font-weight: bold;
  float: right;
}
.totalofdata .table td,
.table th {
  padding: 3px 3px 3px 0.5rem !important;
}
.totalofone {
  text-align: right;
  font-size: 12px;
}
.grandone-1 {
  font-size: 14px;
  margin-bottom: 0px;
  padding-top: 10px;
  font-weight: bold;
}
.grandone {
  float: right;
  font-size: 14px;
  margin-bottom: 0px;
  padding-top: 10px;
  font-weight: bold;
}
.create-auotation {
  background-color: #ea1b37 !important;
  border-color: #fff;
  color: #fff !important;
  margin-right: 0 !important;
  margin-top: 22px;
  width: 100%;
  height: 40px;
  border-radius: 4px;
}
.close-fa {
  text-align: right;
  margin-bottom: 0px;
  cursor: pointer;
  &:hover {
    color: #ea1b37;
  }
}
