.cms-table {
  .ant-table-tbody {
    white-space: normal;
  }
  .ant-table-content {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
