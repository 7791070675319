.modalHeader {
  .ant-modal-header {
    background-color: #00164d !important;

    .ant-modal-title {
      color: #fff;
    }
  }
}
.hotelImgModal {
  .starRating {
    font-size: 12px;
    color: rgb(246, 149, 45);
    .ant-rate-star {
      margin-right: 2px;
    }
  }
}

.activitys_search_list {
  margin-bottom: 20px;
  .activity-card {
    box-shadow: 0 2px 7px #dcdee3;
    margin-bottom: 4px;
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
    .results-wrapper {
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;

      .activity-image {
        grid-column: 1;
        grid-row: 1 / span 3;
        width: 167px;
        position: relative;

        .activity_image_ {
          height: 172px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          @media screen and (max-width: 768px) {
            width: 100%;
            padding: 5px 5px;
          }
          // .gallery-icon {
          //   bottom: 0px;
          //   position: absolute;
          //   right: 0px;
          //   width: 35px;
          //   height: 35px;
          //   background: rgba(28, 43, 57, 0.6);
          //   border-radius: 3px;
          //   text-align: center;
          //   cursor: pointer;
          //   i {
          //     font-size: 20px;
          //     line-height: 35px;
          //     color: #fff;
          //   }
          // }
        }
      }

      .activity-title {
        grid-column: 2 / span 2;
        grid-row: 1;
        padding: 10px;

        .activity-title-wrapper {
          display: flex;
          align-items: baseline;

          .activity-name {
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 700;
            color: #0775e2;
            padding-right: 5px;
            text-transform: capitalize;
            cursor: pointer;
          }
          .activity-star-rating {
            li {
              margin-right: 2px !important;
            }
          }
        }
        .activity-star {
          .starRating {
            font-size: 12px;
            color: rgb(246, 149, 45);
            display: block;
            .ant-rate-star {
              margin-right: 2px;
            }
          }
        }
        .activity-address,
        .activity-duration {
          color: #505c75;
          font-size: 12px;
          display: flex;
          align-items: center;
          font-weight: 600;
          margin-top: 5px;
          .anticon {
            color: #e2076a;
            margin-right: 5px;
          }
          .activity-address-title {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .activity-desc {
          color: #333;
          font-weight: 600;
          margin: 5px 0;
          font-size: 12px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          div {
            p {
              margin: 0 !important;
            }
          }
        }
        .tripadvisor-rating {
          display: flex;
          justify-content: space-between;
          background: #f5f8fd;
          border-radius: 5px;
          cursor: pointer;
          .rating-wrapper {
            display: flex;
            align-items: center;
            .rating-number {
              font-size: 14px;
              padding: 10px;
              font-weight: 600;
              letter-spacing: 3px;
              .sec {
                font-size: 12px;
                font-weight: normal;
                color: #969dac;
              }
            }
            .traveller-count {
              padding: 10px;

              p {
                margin-bottom: 3px;
                font-size: 10px;
                color: #969dac;
                font-weight: 400;
                font-family: "Noto Sans Bold", sans-serif;
                text-transform: capitalize;
                @media screen and (max-width: 768px) {
                  display: none;
                }
              }
              .rating-count-value {
                display: flex;
                align-items: center;
                .tripAd {
                  font-size: 14px;
                  color: #000;
                  margin-right: 5px;
                }
                .tripRating {
                  font-size: 10px;
                  color: #00aa6c;
                  .ant-rate-star {
                    margin-right: 2px;
                  }
                }
              }
            }
            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }
        }
        @media screen and (max-width: 768px) {
          padding: 10px;
        }
      }

      .activity-facilities {
        grid-column: 2 / span 2;
        grid-row: 2;
        padding: 0 15px;
        .amenity-list {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          p {
            font-size: 13px;
            margin-bottom: 5px;
            margin-right: 10px;
            img {
              margin-right: 5px;
            }
            .fa {
              margin-right: 8px;
            }
          }
          .anticon {
            margin-bottom: 5px;
          }
        }
        .showmore-am {
          font-size: 13px;
          padding: 0;
          border: none;
          height: auto;
          color: #0775e2;
        }
      }
      .activity-price-box {
        grid-column: 4;
        grid-row: 1 / span 3;
        background-color: #f6f7f8;
        text-align: center;
        width: 210px;
        display: grid;
        align-items: center;

        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        .activity-price-box-wrapper {
          .activity-price {
            color: #969dac;
            font-size: 18px;
            margin-bottom: 15px;

            span {
              color: #1c2b39;
              line-height: 27px;
              font-size: 24px;
              font-weight: 700;
              @media screen and (max-width: 768px) {
                font-size: 16px;
              }
            }
            .netfare {
              color: #1c2b39;
            }
            @media screen and (max-width: 768px) {
              position: absolute;
              right: 60px;
            }
            .activity-price {
              margin-bottom: 0px;
            }
          }
          .activity-choose-btn {
            padding: 0 30px;

            .web-choose-btn {
              background-color: #ea1b37;
              border-color: transparent;
              color: #fff;
              font-size: 16px;
              font-weight: 700;
              border-radius: 3px;
              padding: 5px 0;
              height: auto;
              @media screen and (max-width: 768px) {
                display: none;
                padding: 0px 7px !important;
                font-size: 14px;
              }
            }
            .mobile-choose-btn {
              width: 40px;
              height: 40px;
              background-color: #ea1b37;
              border-color: transparent;
              @media screen and (min-width: 768px) {
                display: none;
              }
            }
            .select-btn {
              background-color: #ea1b37;
              border-color: transparent;
              color: #fff;
              font-size: 16px;
              font-weight: 700;
              border-radius: 3px;
              padding: 5px 0;
              height: auto;
              width: 100%;
              &:focus,
              &:active,
              &:hover {
                background-color: #ea1b37 !important;
                color: #fff;
              }
            }

            .dark-choose-btn {
              width: 100%;
              background-color: #505c75;
              border-color: transparent;
              color: #fff;
              font-size: 16px;
              font-weight: 700;
              border-radius: 3px;
              padding: 5px 0;
              height: auto;
              &:focus,
              &:active,
              &:hover {
                background-color: #505c75 !important;
                color: #fff;
              }
            }

            @media screen and (max-width: 768px) {
              padding: 0;
            }
          }
          .pax-info {
            margin-top: 10px;
            p {
              color: #505c75;
              max-width: 120px;
              margin: auto;
              font-size: 13px;
              @media screen and (max-width: 768px) {
                max-width: initial;
              }
            }
            @media screen and (max-width: 768px) {
              position: absolute;
              top: 25px;
              right: 60px;
            }
          }
          @media screen and (max-width: 768px) {
            display: flex;
            justify-content: flex-end;
            position: relative;
            padding: 10px;
          }
        }
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
      @media screen and (max-width: 768px) {
        .activity-image {
          grid-column: 1;
          grid-row: 1;
        }

        .activity-title {
          grid-column: 2 / span 3;
          grid-row: 1;
        }

        .activity-facilities {
          grid-column: 1 / span 4;
          grid-row: 2;
        }
        .activity-price-box {
          grid-column: 1 / span 4;
          grid-row: 3;
        }
      }
    }

    .activities-list {
      background-color: #f6f7f8;
      padding: 10px;
      .new-sec-1key {
        background-color: transparent !important;
        .table {
          background-color: #fff;
          margin-bottom: 10px;
          tbody {
            td {
              padding: 0.5rem;
            }
          }
        }
      }
    }
  }
}
/* oct--09-2020--css-added*/

@media screen and (max-width: 768px) {
  .activitys_search_list .results-wrapper {
    display: block !important;
  }

  .activitys_search_list .results-wrapper .activity-image {
    grid-column: 1;
    grid-row: 1 / span 3;
    width: 100% !important;
    position: relative;
  }
  .activitys_search_list
    .results-wrapper
    .activity-price-box
    .activity-price-box-wrapper
    .activity-price {
    position: absolute;
    right: auto !important;
    margin-bottom: 0px !important;
  }

  .activitys_search_list
    .results-wrapper
    .activity-price-box
    .activity-price-box-wrapper
    .pax-info {
    right: auto !important;
  }
  .activitys_search_list
    .results-wrapper
    .activity-price-box
    .activity-price-box-wrapper
    .activity-choose-btn
    .mobile-choose-btn {
    float: right;
  }
  .cp-wrapper-activity {
    display: flex !important;
  }
  .activity-choose-btn {
    display: block !important;
  }
  .activity-cp-price {
    position: relative !important;
  }
}
