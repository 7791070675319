@import "../../assets/variableStyles.scss";

.promocodes {
  .deals-details-wrapper {
    background-image: url("../../assets/images/deals_details_bg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
    margin-top: -67px;
    padding-top: 387px;
    position: relative;
    @include mobile {
      background-position: center;
      padding-top: 250px;
    }
    .bread-crumb-block {
      position: absolute;
      top: 70px;
      ul.breadcrumb {
        padding: 10px 16px;
        list-style: none;
        background-color: transparent;
      }
      ul.breadcrumb li {
        display: inline;
        font-size: 12px;
      }
      ul.breadcrumb li + li:before {
        padding: 8px;
        color: #dcdee3;
        content: "/\00a0";
      }
      ul.breadcrumb li a {
        color: #dcdee3;
        text-decoration: none;
      }
      ul.breadcrumb li a:hover {
        color: #dcdee3;
        text-decoration: underline;
      }
      ul.breadcrumb li span {
        color: #fff;
        font-weight: 700;
      }
    }
  }
  .main-text {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    @include mobile {
      top: 55%;
    }
    h2 {
      color: #fff;
      font-family: "Ubuntu", sans-serif;
      font-size: 45px;
      text-align: center;
      @include mobile {
        font-size: 24px;
      }
    }
  }
}
.promocode-wrapper {
  background-color: #f6f7f8;
  .promocodes-section {
    padding: 25px;
    @include mobile {
      padding: 25px 5px;
    }
    .coupon-card {
      .coupon-content {
        border: solid 1px #ccc;
        margin-top: -8px;
        padding: 10px;
        border-radius: 35px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        background-color: #fff;
        .coupon-code {
          display: flex;
          justify-content: space-between;
          align-items: center;
          p,
          h2 {
            margin-bottom: 0px;
          }
          h2 {
            font-size: 20px;
            @include mobile {
              font-size: 17px;
            }
          }
          .codes {
            p {
              font-size: 11px;
            }
          }
          .copy-code {
            cursor: pointer;
            padding: 5px 10px;
            background-color: #eeffdd;
            color: #417505;
            border: 2px dotted red;
          }
        }
        .coupon-expiry-details {
          display: flex;
          justify-content: space-between;
          .countdown {
            p {
              font-size: 11px;
            }
          }
          .expiry-date {
            color: red;
          }
          .more-details {
            color: blue;
            display: none;
          }
          p {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

.slide-img {
  width: 100%;
  height: 100%;
  border-radius: 35px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
