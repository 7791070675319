@import "../../../assets/variableStyles.scss";
.trip-details-block {
  padding: 15px;
  border-radius: 3px;
  background-color: #fff;
  margin-top: 25px;
  position: sticky;
  top: 225px;
}
.gstdetails,
.pricedetails {
  background-color: #fff !important;
  padding: 0.75rem;
  button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0;
    color: #1c2b39;
    font-size: 14px;
    text-decoration: none;
    &:focus {
      box-shadow: none;
    }
    &:hover {
      text-decoration: none;
    }
    i {
      margin-right: 5px;
    }
  }
}
.cards-body {
  padding: 0.75rem;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin: 3px 15px;
  span {
    &:nth-child(1) {
      margin-left: 20px;
    }
  }
}
.total-fare {
  padding: 15px;
  .price-block {
    display: flex;
    justify-content: space-between;
    .total-text {
      font-size: 16px;
    }
    .price {
      font-size: 16px;
      span {
        font-weight: 600;
      }
    }
  }
  .book-facilities {
    ul {
      margin-bottom: 0;
      li {
        font-size: 12px;
        color: #1da023;
        span {
          border-bottom: 1px dotted #1da023;
        }
        a {
          color: #969dac;
          border-bottom: 1px dotted #969dac;
        }
      }
    }
  }
}

.buscheckout_bg {
  background-color: #eaebee;
  .buscheckout_wrapper {
    max-width: 1200px;
    padding: 20px;
    margin: 0 auto;
    .ant-card {
      border-radius: 8px;
    }
    .paxinfo_wrapper {
      margin-bottom: 25px;
    }
    .title {
      font-size: 18px;
      font-weight: 600;
    }
    .ant-form {
      label {
        color: #05004e;
        font-weight: 600;
      }
      .inputbg,
      .ant-select-selector {
        background-color: #f1f2f6 !important;
      }
      .phno {
        .ant-input-group-addon {
          background-color: transparent;
        }
        input {
          background-color: transparent;
        }
      }
      .btn_wrapper button {
        height: auto;
        padding: 10px 20px;
        font-size: 18px;
        font-weight: 600;
        background-color: #ea1b37 !important;
        color: #fff;
        border: none;
        border-radius: 8px;
      }
    }
  }
}

.offers-modal {
  .coupon-card {
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    .image-wrapper {
      width: 100%;
      height: 120px;
      overflow: hidden;
      img {
        max-width: 100%;
        display: block;
      }
    }
    .coupon-content {
      border: solid 1px #ccc;
      padding: 10px;
      border-radius: 0 0 8px 8px;
      .coupon-code {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .codes {
          flex: 1;
          p {
            font-size: 12px;
            margin-bottom: 0px;
          }
          h2 {
            font-size: 20px;
            margin-bottom: 0px;
            @include mobile {
              font-size: 16px;
            }
          }
        }
        .copy-code {
          cursor: pointer;
          padding: 5px 10px;
          background-color: #ea1b37;
          color: #fff;
          border-radius: 2px;
          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.insurance-section {
  h5 {
    font-weight: 700;
    margin-bottom: 0;
  }
  p {
    font-size: 12px;
    color: #666;
    font-weight: 600;
  }

  .insurance-box {
    border: 1px solid #000;
    border-radius: 8px;
    color: #000;
    font-weight: 600;
    text-align: center;
    padding: 8px;
    height: 100%;
  }
  .insurance-coverage {
    display: flex;
    align-items: center;
    color: #000000;
    margin: 10px 0 20px 0;
    font-weight: 600;
    font-size: 13px;
    .anticon {
      margin-right: 10px;
    }
  }

  .insurance-radio-wrapper {
    width: 100%;
    margin-bottom: 5px;
    .radio-btn {
      padding: 8px 20px;
      width: 100%;
      justify-content: center;
      font-size: 16px;
    }
    .btn-insure {
      border: 1px solid #2a76e3;
      color: #2a76e3 !important;
    }

    .btn-risk {
      border: 1px solid #d9d9d9;
      background-color: rgb(247, 247, 247) !important;
      color: rgb(136, 138, 145) !important;
    }

    .btn-insure-active {
      background-color: rgb(42, 118, 227);
      color: #fff !important;
    }

    .btn-risk-active {
      background-color: rgb(218, 219, 220) !important;
      color: rgb(136, 138, 145) !important;
    }
  }

  // .insurance-btn {
  //   .ant-btn {
  //     width: 100%;
  //     font-weight: 600;
  //   }
  //   .btn-insure {
  //     border: 1px solid #2a76e3;
  //     color: #2a76e3;
  //     &:focus,
  //     &:active {
  //       background-color: #2a76e3 !important;
  //       color: #fff;
  //     }
  //   }

  //   .btn-risk {
  //     background-color: rgb(247, 247, 247);
  //     color: rgb(136, 138, 145);
  //     &:focus,
  //     &:active {
  //       background-color: rgb(218, 219, 220) !important;
  //       color: rgb(136, 138, 145);
  //       border: 0px;
  //     }
  //   }
  //   .btn-insure-active {
  //     background-color: rgb(42, 118, 227);
  //     color: #fff !important;
  //   }

  //   .btn-risk-active {
  //     background-color: rgb(218, 219, 220) !important;
  //     color: rgb(136, 138, 145) !important;
  //   }
  // }
}
