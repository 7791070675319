.hotel-extranet {
  width: 500px !important;
  .ant-form-item {
    margin-bottom: 5px;
  }
  .ant-upload {
    width: 90px;
    height: 70px;
  }
}
