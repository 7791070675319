@import "../../../assets/variableStyles.scss";
.hotel-sort-block {
  margin-bottom: 15px;
  .sort-text {
    margin-bottom: 0;
    font-weight: 600;
  }
  .results-sort-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    .flex-wrapper {
      display: flex;
      align-items: center;
    }
    .ant-radio-group {
      display: flex;
      align-items: center;
      .ant-radio-wrapper {
        margin-left: 25px;
        margin-right: 0;
        .price-type {
          span {
            padding-right: 5px;
          }
        }
      }
    }
    .ant-input-group {
      max-width: 330px;
      min-width: 220px;
      .search-type-box {
        max-width: 55px;
      }
      .search-select-box {
        width: calc(100% - 55px);
      }
    }
  }
  @include mobile {
    display: none !important;
  }
}
