
@import "../../assets/variableStyles.scss";
.add-deposit-icon {
    font-size: 30px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.from-to-block {
    display: flex;
    .date-pickers {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 10px;
    }
}
.cancel-btn {
    margin-left: 10px;
}
.upload-files {
    text-align: end;
    margin-bottom: 24px;
    @include mobile {
        text-align: start;
    }
}
.ant-table-thead,thead{
    border-spacing: 0 10px;
    
    overflow: hidden
}

.payment-details-block {
    margin-bottom: 15px;
    margin-left: 33%;
    @include mobile {
        margin-left: 0;
    }
    h5 {
        font-size: 16px;
        font-weight: 600;
    }
    ul {
        li {
            display: flex;
            justify-content: space-between;
            p {
                margin-bottom: 0;
            }
        }
    }
}
@include mobile {
    .certify-text {
        .ant-form-item-control  {
            margin-left: auto;
        }
    }
}