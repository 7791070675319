.cart-des li {
  float: left;
}
.activity-p {
  padding-top: 7px;
  padding-left: 110px;
}
.cart-body .ant-card-body {
  padding: 0px !important;
}
.dev-cart {
  padding: 10px 10px;
}

.activity-p1 {
  padding-top: 7px;
  padding-left: 230px;
}

.activity-p2 {
  padding-top: 7px;
  padding-left: 230px;
}

.activity-p3 {
  padding-top: 7px;
  padding-left: 230px;
}

.img-cart {
  width: 100px;
  height: 80px;
  margin-left: 11px;
  padding-bottom: 10px;
}
.activity-pimg {
  padding-top: 7px;
  padding-left: 27px;
  margin-bottom: 0em !important;
}
.activity-pimg1 {
  padding-left: 27px;
  margin-bottom: 0em !important;
}

.activity-pimg2 {
  padding-left: 73px;
  margin-bottom: 0em !important;
}
.activity-pimg3 {
  padding-left: 190px;
}
.activity-pimg4 {
  padding-left: 95px;
}

.activity-pimg5 {
  padding-left: 89px;
}
.border-cart {
  border-top: 1px solid #000;
  margin-top: 80px;
}
.delete-cart {
  padding-left: 20px;
}
.edit-cart {
  padding-left: 79px;
}
.sub-total {
  position: absolute;
  right: 147px;
}
.all-total-cost {
  margin-top: 20px;
}
.activity-ptotal {
  padding-left: 319px;
  margin-bottom: 0em !important;
}
.activity-paynow {
  padding-left: 40px;
}
@media screen and (max-width: 767px) {
  .cart-des li {
    float: none;
  }
  .activity-p {
    padding-top: 7px;
    padding-left: 0px;
  }
  .activity-p1 {
    padding-top: 7px;
    padding-left: 0px;
  }
  .activity-p2 {
    padding-top: 7px;
    padding-left: 0px;
  }
  .activity-p3 {
    padding-top: 7px;
    padding-left: 0px;
  }
  .img-cart {
    margin-left: 0px;
  }
  .activity-pimg {
    padding-left: 0px;
    margin-bottom: 0em !important;
  }
  .activity-pimg1 {
    padding-left: 0px;
    margin-bottom: 0em !important;
  }
  .activity-pimg3 {
    padding-left: 0px;
  }
  .activity-pimg4 {
    padding-left: 0px;
  }
  .delete-cart {
    padding-left: 0px;
  }
  .edit-cart {
    padding-left: 0px;
  }
  .sub-total {
    bottom: -10px;
    position: absolute;
    right: 0;
    left: 13px;
  }

  .border-cart {
    border-top: 1px solid #000;
    margin-top: 30px;
  }

  .activity-pimg2 {
    padding-left: 0px;
    margin-bottom: 0em !important;
  }
  .activity-paynow {
    padding-top: 7px;
    padding-left: 0px;
  }
  .activity-ptotal {
    padding-left: 0px;
  }
  .activity-paynow {
    padding-left: 0px;
  }
}
