@import "../../assets/variableStyles.scss";
#banner {
  // .carousel {
  //   margin-top: -31px;
  //   height: 21em;
  //   width: 100%;
  //   min-height: 230px;
  //   position: relative;
  //   background-color: #2f81ed;
  //   background-image: linear-gradient(to right, #2f80ed, #56ccf2);
  //   @include mobile {
  //     display: none;
  //   }
  //   .carousel-inner {
  //     height: 100%;
  //     display: none;
  //   }
  // }
  // .banner-heading {
  //   max-width: 850px;
  //   margin: auto;
  //   padding: 40px 50px;
  // }
  img {
    height: 21em;
    width: 100%;
    min-height: 230px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
@media screen and (max-width: 768px) {
  .fh-main-cc2{
    display: none;
  }
}