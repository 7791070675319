.flight-filters {
  .flight-result-indicator {
    p {
      font-weight: bold;
      color: #000;
      margin-bottom: 10px;
      padding-bottom: 2px;
      border-bottom: 1px solid #d9d9d9;
    }
  }
  .overall-filter-container {
    .overall-filter-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0px;
      padding-bottom: 2px;
      border-bottom: 1px solid #d9d9d9;
      .filter-text {
        font-weight: bold;
        color: #000;
        margin-bottom: 0px;
      }
      .clear-text {
        cursor: pointer;
        font-size: 12px;
        color: #0775e2;
        margin-bottom: 0px;
      }
    }
    .overall-filter-body {
      .ant-collapse {
        background: transparent;
        border: transparent;
        .ant-collapse-item {
          border: transparent;
          .ant-collapse-header {
            padding: 12px 0;
            font-weight: bold;
            color: #000;
            // .anticon {
            //     width: 100%;
            //     text-align: end;
            //     left: 0;
            // }
          }
        }
      }
      .stops-filter,
      .slider-filter,
      .airlines-filter,
      .fare-filter,
      .fare-types-filter,
      .connecting-locations {
        .ant-collapse-content-box {
          padding: 16px 0;
        }
      }
    }
  }
  .filter-labels {
    p {
      margin-bottom: 0;
      span {
        &:nth-child(2) {
          margin-left: 15px;
          font-size: 12px;
          font-weight: normal;
          color: #0775e2;
        }
      }
    }
  }
  .ant-collapse-header {
    .ant-collapse-extra {
      span {
        margin-right: 15px;
        font-size: 12px;
        font-weight: normal;
        color: #0775e2;
      }
    }
  }
  .ant-collapse-content-box {
    .ant-checkbox-wrapper {
      font-size: 13px;
      display: flex;
      width: 100%;
    }

    .ant-checkbox + span {
      flex: 1;
    }
    .slider-label {
      span {
        &:nth-child(2) {
          float: right;
        }
      }
    }
  }
}

.slider-icon-1 .ant-slider {
  margin: 10px 8px !important;
}
.slider-icon-1 .input-range {
  height: 1rem;
  position: relative;
  width: 93%;
}

.slider-icon-1 .input-range__slider:active {
  transform: scale(1);
}

.slider-icon-1 .input-range__slider {
  margin-left: 0rem;
  margin-top: -0.65rem;
}
.slider-icon-1
  .ant-collapse-icon-position-right
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  right: 0px;
  left: auto;
  bottom: 13px;
}

.flight-filters .ant-slider-handle {
  background: #3f51b5;
  border: 1px solid #3f51b5;
}
.flight-filters .ant-slider-track {
  background: #3f51b5;
}
.flight-filters .ant-slider:hover {
  border-color: #3f51b5 !important;
}

.flight-filters .ant-slider:hover .ant-slider-track {
  background-color: #3f51b5 !important;
}
.flight-filters .input-range__slider {
  width: 14px;
  height: 14px;
}
