// @import "admin/adminIndex.scss";
@import "./assets/variableStyles.scss";

@font-face {
  font-family: "Ubuntu";
  src: url(assets/fonts/Ubuntu/Ubuntu-Bold.ttf) format("truetype");

  // src: url(assets/fonts/Ubuntu/Ubuntu-Regular.ttf) format("truetype");
  src: url(assets/fonts/Ubuntu/Ubuntu-Light.ttf) format("truetype");
  // src: url(assets/fonts/Ubuntu/Ubuntu-Medium.ttf) format("truetype");
  // font-weight: normal;
}
@font-face {
  font-family: "Noto Sans";
  src: url(assets/fonts/NotoSans/NotoSans-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Noto Sans Bold";
  src: url(assets/fonts/NotoSans/NotoSans-Bold.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: Noto Sans, sans-serif !important;
  color: #1c2b39;
  font-size: 14px;
}
ul {
  padding: 0;
  margin: 0 !important;
}
li {
  list-style-type: none;
}
a,
a:hover {
  text-decoration: none !important;
}

textarea:focus,
input:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: transparent;
  // box-shadow: 0 0 1px #ddd, inset 0 0px 1px #ddd;
  outline: 0 none;
}
button:focus {
  outline: transparent;
}
.e-hide {
  display: none !important;
}
.e-visible {
  display: block !important;
}

.ant-table-thead > tr > th,
.ant-table tfoot > tr > th {
  padding: 6px 8px !important;
}

.ant-table-tbody > tr > td,
.ant-table tfoot > tr > td {
  padding: 4px 8px !important;
}
.ant-table-column-sorters {
  padding: 0 !important;
}
// .rc-virtual-list-holder {
//   max-height: 356px !important;
// }
@include tab {
  .e-md-visible {
    display: block !important;
  }
  .e-md-hide {
    display: none !important;
  }
}
@include desktop {
  .e-lg-visible {
    display: block !important;
  }
  .e-lg-hide {
    display: none !important;
  }
}

.payment-btn {
  background-color: #ea1b37;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 21px;
  font-weight: 600;
  padding: 10px 31px;
  height: 100%;
  @include mobile {
    font-size: 16px;
    padding: 10px 20px;
  }
}
.print-agentlogo {
  display: none !important;
}
@include print {
  body {
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
    .ant-card-body,
    .ant-collapse-content-box {
      padding: 0 !important;
      .ant-table-thead tr th {
        background: #d5436a !important;
        color: #fff !important;
      }
    }
    .print-agentlogo {
      display: block !important;
      .printImageStyle {
        width: 100px;
        height: 50px;
      }
    }

    .fligh-ticket-container {
      padding: 15px !important;
      margin: 0 !important;
      width: 100% !important;
    }
    .ticket-row {
      flex-direction: column !important;
      .ant-col {
        max-width: 100% !important;
      }
      .tic-info-flight {
        display: none !important;
      }
    }
    .header-bg {
      display: none !important;
      .header-container {
        .top-nav-sec,
        .user-icon,
        .app-links {
          display: none !important;
        }
      }
    }
    #footer,
    .ant-dropdown {
      display: none !important;
    }
    #admin {
      .ant-layout {
        .ant-layout-sider {
          display: none !important;
        }
      }
      .ant-layout-header {
        display: none !important;
      }
      .footer {
        display: none !important;
      }
    }
  }
}

iframe {
  width: 100%;
  height: 400px;
}
.text-line {
  text-decoration: line-through;
}

.word-break {
  word-break: break-all;
}
