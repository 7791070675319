@import "../../../assets/variableStyles.scss";
.edit-onward {
  margin-bottom: 15px;
  .card-results {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: rgba(19, 41, 104, 0.2) 0px 2px 5px 0px;
    padding: 15px;
    margin-bottom: 15px;
    .flight-border-right {
      border-right: 1px solid #eee;
      @include mobile {
        border-right: none;
      }
    }
    .selected-flights-price-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      @include smmobile {
        display: block;
      }
      .selected-flights-price {
        text-align: center;
        @include smmobile {
          padding-top: 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid #eee;
        }
        .price-box {
          .totalFare {
            font-weight: 600;
            @include smmobile {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .card-results-travel-wrapper {
      .title {
        font-weight: 600;
        margin-bottom: 3px;
        font-size: 14px;
        color: #d5436a;
        @include mobile {
          display: inline-block;
          margin-right: 5px;
        }
      }

      .card-results-travel-details {
        display: flex;
        justify-content: space-between;
        @include mobile {
          flex-direction: column;
        }
        // .onward_wrapper {
        //   padding-right: 10px;
        //   border-right: 2px solid #eee;
        //   color: #d5436a;
        //   @include mobile {
        //     padding-right: 5px;
        //     border: none;
        //   }
        //   p {
        //     font-weight: 600;
        //     margin-bottom: 3px;
        //     font-size: 14px;
        //     @include mobile {
        //       display: inline-block;
        //       margin-right: 5px;
        //     }
        //   }
        // }
        .itinary_wrapper {
          flex: 1;
          max-width: 700px;
          margin-right: auto;
          @include mobile {
            max-width: 100%;
            margin-right: 0;
          }
          .itinary-details {
            display: flex;
            flex: 1;

            @include mobile {
              flex-direction: column;
            }
            .itinary-flight-name {
              text-align: center;
              @include mobile {
                display: flex;
                align-items: center;
              }
              .airline-img {
                max-width: 25px;
              }
              .name {
                font-weight: 600;
                font-size: 14px;
              }
              p {
                margin-bottom: 0;
                padding: 2px;
              }
            }
            .fromTo-wrapper {
              flex: 1;
              .cities-from-to {
                display: flex;
                flex-direction: column;
                position: relative;
                padding-right: 10px;
                flex: 1;
                .loc_wrapper {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .name {
                    font-weight: 600;
                    margin-right: 5px;
                  }
                  .code {
                    font-size: 12px;
                    font-weight: 600;
                  }
                }
              }
            }
          }

          .flight-stops-wrapper {
            text-align: right;
            margin-top: 5px;
            .stops-wrapper {
              font-weight: 900;
              font-size: 11px;
              letter-spacing: 0.5px;
            }
          }
          // .slectedtiming {
          //   display: flex;
          //   align-items: center;
          //   justify-content: space-between;
          //   padding: 0 10px;
          //   .flight-timings {
          //     flex: 1;
          //   }
          //   .timing_wrapper {
          //     flex-wrap: wrap;
          //     .plane-timings {
          //       p {
          //         font-size: 14px;
          //       }
          //     }
          //   }
          //   .travel-time {
          //     p {
          //       font-size: 14px;
          //     }
          //   }
          //   .details-btn {
          //     display: none !important;
          //   }
          // }
        }
      }
    }
  }

  .btncontainer {
    display: flex;
    justify-content: space-between;
    @include mobile {
      flex-direction: row;
      padding: 10px 10px 0 10px;
    }
    .price {
      font-size: 14px;
      font-weight: 600;
      margin: 0;
      @include mobile {
        flex: 1;
      }
      span {
        font-size: 12px;
        margin-right: 5px;
      }
    }
    .tg-primary-btn {
      font-size: 12px;
      background-color: transparent;
      border: none;
      outline: none;
      color: #0775e2;
      font-weight: 600;
    }
  }

  .returntext {
    color: #d5436a;
    font-weight: 600;
  }
}
