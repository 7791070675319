#subscribe {
  background-color: #00164d;
  color: #fff;
  .subscribe-wrapper {
    max-width: 710px;
    margin: 0 auto;
    padding: 40px 50px;
    text-align: center;
    // h2 {
    //   color: #fff;
    //   font-weight: 700;
    // }
    // h3 {
    //   font-weight: 600;
    //   color: #fff;
    //   font-size: 29px;
    // }
    p.sub-title {
      color: #969dac;
      font-size: 20px;
    }
    // .form-row {
    //   margin-bottom: 25px;
    //   .subscribe-btn {
    //     padding: 8px 30px;
    //     font-weight: 100;
    //   }
    // }
    // .custom-control {
    //   label.custom-control-label {
    //     font-size: 87%;
    //     color: #969dac;
    //     text-align: left;
    //   }
    // }
    .ant-form {
      .ant-input-affix-wrapper {
        overflow: hidden;
        border-radius: 0.3rem;
      }
      input {
        height: 45px;
        font-size: 18px;
      }
      button {
        height: 55px;
        width: 100%;
        font-size: 20px;
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
        border-radius: 0.3rem;
        &:hover,
        &:focus,
        &:active {
          background-color: #0069d9;
          border-color: #0062cc;
        }
      }
      .ant-checkbox-wrapper {
        font-size: 12px;
        color: #969dac;
      }
    }
  }
  // .subscribe-thanking {
  //   max-width: 630px;
  //   h4 {
  //     font-weight: 600;
  //     color: #fff;
  //     font-size: 19px;
  //     margin-bottom: 30px;
  //   }
  //   .add-email-subscribe {
  //     font-size: larger;
  //     padding-top: 10px;
  //     padding-bottom: 10px;
  //     border-radius: 3px;
  //   }
  // }
}
