@import "../../assets/variableStyles.scss";

.checkout-heading {
  background: #002172;
  padding: 15px 0;
  .checkout-heading-container {
    max-width: 1100px;
    margin: auto;
    padding: 0 15px;
    .goback {
      margin-bottom: 10px;
      font-size: 14px;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      .anticon-arrow-left {
        margin-right: 7px;
      }
      a {
        color: #fff;
        display: flex;
        align-items: center;
      }
    }
  }
  h3 {
    color: #fff;
    margin-bottom: 0px;
    @include mobile {
      font-size: 16px;
    }
  }
}
.checkout-section {
  .service-cahrge-table {
    .service-charge-header {
      background-color: #1890ff;
      ul {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        li {
          color: #fff;
        }
      }
    }
    .service-charge-body {
      ul {
        display: flex;
        justify-content: space-between;
        padding: 10px 15px;
        border: 1px solid #969dac;
        border-top: none;
      }
    }
  }
}
.flight-checkout {
  width: 100%;
  height: 100%;
}

.checkout-body-sec {
  background: #f6f7f8;
  padding: 20px 35px;
  @include mobile {
    padding: 15px 0px;
  }

  .checkout-container {
    max-width: 1100px;
    margin: auto;
    padding: 0 15px;

    .checkout-bottom-part {
      @include mobile {
        order: 1;
      }
    }
    .checkout-top-part {
      @include mobile {
        order: 0;
      }
    }
    .checkout-sticky-part {
      // position: sticky;
      // top: 0;
      .grand-total-card {
        p {
          margin-bottom: 0;
        }
        .pax-total-price {
          display: flex;
          justify-content: space-between;
          .pax-type {
            .pax-comm {
              font-size: 15px !important;
            }
            p {
              font-size: 18px;
              margin-bottom: 0;
            }
          }
          .total {
            display: flex;
            p {
              font-size: 20px;
            }
            .amount {
              font-weight: 700;
            }
            .pax-comm {
              font-size: 15px !important;
            }
          }
        }
      }
    }
    .user-details {
      margin-bottom: 15px;
      .heading {
        color: #1c2b39;
        font-weight: 100;
        font-size: 20px;
        @include mobile {
          margin-bottom: 10px;
          font-size: 16px;
        }
      }
      .ant-input-group-wrapper {
        .ant-input-group-addon {
          width: 35%;
          padding: 0;
        }
      }
      .checkout-custom-card {
        padding: 16px 24px;
        box-shadow: 0 2px 2px 0 #dcdee3;
        border: none;
        margin-bottom: 20px;
        .ant-card-body {
          padding: 0;
        }
        .card-adult-text {
          margin-bottom: 13px;
          @include mobile {
            flex-direction: row;
            flex-wrap: inherit;
          }
          .col-md-3,
          .col-md-9 {
            @include mobile {
              padding: 0px;
            }
          }
        }
        .pax-heading {
          font-weight: 600;
          margin-bottom: 4px;
        }
        .pax-head-hr {
          hr {
            border-top: 1px solid #bcd5f5;
            max-width: 100%;
          }
        }
        .pax-info-form {
          .form-group {
            margin-bottom: 25px !important;
            @include mobile {
              margin-bottom: 0px !important;
            }
            .plaintext {
              border: none !important;
            }
            input[type="text"] {
              border: solid 1px #bcd5f5 !important;
              // height: 45px;
              @include mobile {
                font-size: 15px;
                height: 45px;
              }
            }
            label {
              color: #505c75;
              @include mobile {
                font-weight: 600;
              }
              span {
                color: red;
                font-size: 18px;
              }
            }
            select {
              border: solid 1px #bcd5f5;
              @include mobile {
                height: 45px;
                font-size: 15px;
              }
            }
            .title-select {
              max-width: 130px;
              border: solid 1px #bcd5f5;
              @include mobile {
                max-width: 100%;
              }
            }
            .dob-select {
              display: flex;

              @include mobile {
                flex-direction: column;
                align-items: flex-start;
              }
              .title-select {
                margin-right: 10px;
                @include mobile {
                  margin-bottom: 18px;
                  margin-right: 0px;
                }
              }
              input {
                max-width: 130px !important;
                @include mobile {
                  max-width: 100%;
                }
              }
            }
            .Country-select {
              max-width: 200px !important;
              @include mobile {
                max-width: 100%;
              }
            }
          }
          .contact-details-text {
            p {
              font-size: 13px;
              color: #969dac;
              @include mobile {
                font-size: 12px;
                margin-top: 10px;
              }
            }
          }
          .register-check {
            display: flex;
            p {
              font-size: 15px;
            }
            span {
              color: #e2076a;
              font-weight: 700;
            }
          }
        }
        .hr-line {
          margin-top: 0px !important;
          @include mobile {
            display: none;
          }
        }
        .airline-bottom-section {
          .airline-bottom-section-left {
            .trip-timing {
              text-align: center;
              p {
                font-weight: 700;
              }
              .trip-row-mobile {
                @include mobile {
                  flex-wrap: nowrap;
                  justify-content: flex-start;
                }
                .col-md-2 {
                  i {
                    @include mobile {
                      margin-left: -33px;
                    }
                  }
                }
                .arrive-time {
                  p {
                    @include mobile {
                      margin-left: -38px;
                    }
                  }
                }
              }
            }
            .trip-duration {
              display: flex;
              span {
                color: #969dac;
                font-size: 13px;
              }
              p {
                font-size: 14px;
                padding-left: 4px;
              }
            }
          }
          .airline-bottom-section-right {
            text-align: right;
            button {
              background: none;
              color: #007bff;
              font-size: 13px;
              font-weight: 700;
              padding: 6px 16px;
              &:hover {
                background-color: #007bff;
                color: #fff;
              }
            }
          }
        }
      }
      h3 {
        @include mobile {
          margin-bottom: 12px;
        }
      }
    }

    .change-flight-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        font-size: 20px;
      }
      p {
        color: #007bff;
        cursor: pointer;
      }
    }
    .flight-details-card {
      margin-bottom: 20px;
    }
    .book-pay-section {
      margin: 25px 0px;
      .ant-card-body {
        padding: 0;
      }
      .book-pay-card {
        padding: 16px 24px;
        box-shadow: 0 2px 2px 0 #dcdee3;
        border: none;
        @include mobile {
          padding: 16px;
        }
        .book-pay-tc {
          display: flex;
          span {
            color: red;
          }
          p {
            margin-bottom: 0;
          }
        }
        .book-pay-offers {
          display: flex;
          p {
            font-size: 15px;
            a {
              &:hover {
                color: #007bff;
              }
            }
          }
        }
        .book-pay-btn {
          button {
            background-color: #ea1b37;
            color: #fff;
            border: none;
            font-size: 20px;
            font-weight: 600;
            padding: 10px 20px;
            height: 100%;
            @include mobile {
              font-size: 16px;
              padding: 10px 15px;
            }
          }
          .book-and-content {
            padding-top: 20px;
            p {
              font-size: 15px;
              color: #969dac;
            }
          }
        }
      }
    }
  }
}

.payer-radio-group {
  @include mobile {
    flex-wrap: inherit;
  }
}
.contact-number {
  display: flex;
  .dob-select {
    margin-right: 10px;
  }
}
.pax-count-card {
  border: none;

  .accordion {
    border-bottom: solid 1px #ccc;
    margin-bottom: 10px;
    padding: 5px 15px;
    // padding-bottom: 0px;
    @include mobile {
      margin-bottom: 0px;
    }
  }
  .mobile_acc {
    border-bottom: none !important;
    padding: 5px;
  }
  .all-taxes {
    font-size: 12px;
    color: #969dac;
    margin-bottom: 0px !important;
    margin-bottom: 10px !important;
  }
  .pax-total-price {
    display: flex;
    justify-content: space-between;

    // padding-bottom: 0px;
    .pax-type {
      p {
        font-size: 18px;
        margin-bottom: 0px;
      }
      .pax-comm {
        font-size: 15px !important;
      }
    }
    .total {
      display: flex;
      i {
        @include mobile {
          font-size: 25px;
          padding-left: 7px;
        }
      }
      p {
        font-size: 20px;
        margin-bottom: 0px;
      }
      .pax-comm {
        font-size: 15px !important;
      }
      .amount {
        font-size: 20px;
        margin-bottom: 0px;
        font-weight: 700;
      }
    }
  }
  .pax-pre-price {
    display: flex;
    justify-content: space-between;
    padding: 5px 15px;
    // padding-bottom: 0px;
    .pax-type {
      p {
        font-size: 16px;
        margin-bottom: 0;
        @include mobile {
          margin-bottom: 0px;
          font-weight: 600;
        }
      }
    }
    .total {
      display: flex;
      i {
        @include mobile {
          font-size: 25px;
          padding-left: 7px;
        }
      }
      p {
        font-size: 16px;
        margin-bottom: 0px;
      }
      .amount {
        font-size: 16px;
        margin-bottom: 0px;
        font-weight: 700;
      }
    }
  }
  .service-price {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 14px;
      margin-bottom: 0;
    }
    .main-price {
      font-size: 14px;
      font-weight: 700;
      padding-left: 6px;
    }
  }

  .pax-count-card-header {
    background-color: #fff;
    border-bottom: none;
    padding: 0px;
  }
}
.pax-count-header-top {
  display: flex;
  justify-content: space-between;
  line-height: 1;
  @include mobile {
    padding: 8px 0px;
  }
  cursor: pointer;
  .pax-header-left-sec {
    display: flex;
    p {
      padding-left: 6px;
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}
.border-line {
  border-top: solid 1px #ccc !important;
  // margin-top: 15px;
}

.pricepopup {
  .ant-popover-title {
    padding: 6px 12px;
    font-weight: bold;
  }
  .ant-popover-inner-content {
    padding: 7px 12px 12px 12px;
    .pax-count-acc-body {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      color: #505c75;
      padding-top: 5px;

      p {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 0px;
      }
    }
  }
}
.details-modal {
  .details-header {
    p {
      margin-bottom: 0px;
      font-size: 20px;
      font-weight: 700;
    }
    button {
      font-size: 34px;
    }
  }
  .modal-body {
    padding: 0px 15px;
  }
}

.faresummary_mobile {
  display: none;
  @include mobile {
    display: block;
    margin-top: 23px;
  }
}
/********** Antd Styles *******/
.passenger-form {
  .ant-form-item-label {
    text-align: start;
  }
}
.flight-checkout-contact-form {
  .ant-form-item-label {
    text-align: start;
  }
}
/********** fare summary card **********/
.sticky-card-container {
  // position: sticky;
  // top: 0;
  margin-bottom: 20px;
  .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    background-color: #fff;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background-color: #fff;
  }
  .ant-collapse-content-box {
    padding-top: 16px !important;
    p {
      margin-bottom: 0;
    }
  }
}
.airline-details-left {
  .airline-dte {
    display: flex;
    align-items: center;
    @include mobile {
      flex-direction: column;
      order: 2;
    }
    h5 {
      font-weight: 700;
      margin-bottom: 0;
      @include mobile {
        font-size: 15px;
        margin-bottom: 5px !important;
      }
    }
    span {
      font-size: 14px;
      color: #969dac;
      margin-left: 10px;
      @include mobile {
        margin-left: 0;
        font-size: 13px;
      }
    }
  }
  .airline-src-dest {
    display: flex;
    padding-top: 6px;
    align-items: center;
    p {
      font-weight: 600;
      margin-bottom: 0;
    }
    .airline-line {
      font-size: 12px;
      width: 100%;
      border-top: solid 1px #969dac;
      margin: 6px 9px;
    }
  }
}

.airline-details-right {
  text-align: right;
  padding-top: 10px;
  p {
    font-weight: 700;
    font-size: 14px;
  }
}
.direct-text {
  p {
    margin-bottom: 0;
    font-size: 13px;
    color: #000;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.airline-details {
  border-bottom: 1px solid #969dac;
  padding-bottom: 15px;
  @include mobile {
    border-bottom: 0;
    padding-bottom: 0;
  }
  .airline-row-mobile {
    align-items: center;
    @include mobile {
      flex-wrap: nowrap !important;
      justify-content: space-between;
    }
    .col-md-5 {
      @include mobile {
        order: 1;
      }
      .airline-dte {
        @include mobile {
          padding: 2px 10px;
        }
        h5 {
          @include mobile {
            font-size: 13px;
          }
        }
        span {
          @include mobile {
            font-size: 10px;
          }
        }
      }
    }
    .col-md-3 {
      @include mobile {
        padding: 0px 2px;
        order: 0;
        max-width: 62px;
      }
    }
    .src-dest-mobile {
      @include mobile {
        display: none;
      }
    }
  }
  .airline-details-left {
    .airline-dte {
      display: flex;
      align-items: center;
      h5 {
        font-weight: 700;
        margin-bottom: 0;
      }
    }

    .airline-src-dest {
      display: flex;
      padding-top: 6px;
      p {
        font-weight: bold;
        color: #000;
      }
      .airline-line {
        font-size: 12px;
        width: 100%;
        border-top: solid 1px #969dac;
        margin: 6px 9px;
      }
    }
  }

  .airline-details-right {
    text-align: right;
    padding-top: 10px;
    p {
      font-weight: 700;
      font-size: 14px;
    }
  }
}
.trip-details {
  padding: 15px 0 0;
  .ant-row {
    align-items: center;
    @include mobile {
      justify-content: space-between;
    }
  }
  .trip-timimg-details {
    ul {
      display: flex;
      li {
        margin-right: 15px;
        font-weight: bold;
      }
    }
  }
  .duration-timing {
    display: flex;
    font-size: 12px;
    @include mobile {
      display: none;
    }
    p {
      margin-bottom: 0;
      color: #969dac;
    }
    span {
      color: #000;
    }
  }
  .details-btn {
    text-align: end;
  }
}
.trip-duration-strip {
  background: #eaebee;
  .trip-time {
    display: flex;
    padding: 20px 0px;
    align-items: center;
    padding-left: 40px;
    i {
      font-size: 20px;
    }
    p {
      margin-bottom: 0px;
      font-size: 15px;
      padding-left: 7px;
    }
  }
}
.flight-cards-details {
  background-color: #fff !important;
  border-radius: 8px !important;
  box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2) !important;
  font-family: GT Walsheim, Helvetica, Arial, sans-serif !important;
  .fareTitle {
    font-weight: 600;
  }
  .grand_total_card_row {
    padding: 0;
    p {
      margin-bottom: 14px;
      font-size: 14px;
    }
    .d-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .anticon-question-circle {
        margin: 0 0 14px 5px;
        color: #969dac;
        cursor: pointer;
      }
    }
  }
}
.flight-details-modal-container {
  .trip-body {
    padding-left: 40px;
    padding-top: 14px;
    .trip-strip {
      position: relative;
      &::before {
        display: block;
        content: "";
        position: absolute;
        top: 10px;
        left: -13px;
        bottom: 24px;
        background: #f9b975;
        width: 1px;
      }
    }
    .trip-body-details {
      display: flex;
      position: relative;
      &::before {
        display: block;
        background-color: #f9b975;
        -moz-border-radius: 50%;
        border-radius: 50%;
        width: 5px;
        height: 5px;
        position: absolute;
        left: -15px;
        top: 9px;
        content: "";
      }
      p {
        font-size: 15px;
        &:nth-child(1) {
          margin-right: 30px;
        }
      }
    }
  }
  .trip-bottom-duration {
    padding-left: 40px;
    padding-bottom: 20px;
    p {
      margin-bottom: 0px;
      font-size: 13px;
    }
  }
  .ant-modal-body {
    padding: 0 !important;
  }
}
.gst-details {
  .heading {
    color: #1c2b39;
    font-weight: 100;
    font-size: 20px;
    @include mobile {
      margin-bottom: 10px;
      font-size: 16px;
    }
  }
}
.add-gst-text {
  display: flex;
  align-items: center;
  h4 {
    margin-bottom: 0;
    margin-right: 10px;
    font-size: 20px;
    @include mobile {
      font-size: 15px;
    }
  }
}
.add-gst-block {
  .ant-collapse-borderless {
    background-color: white;
  }
  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: transparent;
  }
}
.promo-card {
  margin-top: 25px;
  @include mobile {
    margin-top: 20px;
  }
  .promo-card-header {
    .name {
      font-size: 18px !important;
    }
  }
  .promo-input {
    .applied {
      color: #d5436a;
      font-weight: 600;
    }
    .promo-input-item {
      width: 70%;
      @include mobile {
        width: auto;
      }
    }
  }
  .ant-form {
    button {
      background-color: #d5436a;
      color: #fff;
      border: none;
      font-size: 16px;
    }
  }
  .promo-input-btn {
    margin-right: 0 !important;
    background-color: #d5436a;
    color: #fff;
    border: none;
    font-size: 16px;
  }
}
.service-charge {
  margin-top: 25px;
  .service-charge-header {
    h4 {
      margin-bottom: 25px;
      font-size: 23px;
      @include mobile {
        font-size: 16px;
        margin-bottom: 15px;
      }
    }
  }
  .service-charge-table {
    .ant-table {
      overflow: hidden;
    }
  }
}
.responsive-departure {
  @include mobile {
    order: 2;
  }
}
.flight-review-row {
  @include mobile {
    flex-wrap: wrap-reverse !important;
  }
}
.passport-dates {
  .ant-picker {
    width: 100%;
  }
}
.discount-price {
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
  .total {
    display: flex;
    p {
      margin-right: 5px;
    }
  }
}

.flights-poins-sec .ant-card-bordered {
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(19, 41, 104, 0.2);
}
.grand-total-card .ant-card-bordered {
  border-radius: 8px;
}
.flights-poins-sec .flight-syt-time {
  position: relative;
  left: 0px;
  top: 0px;
}
.flights-poins-sec .details-bag-icon {
  position: relative !important;
  top: 0px !important;
  right: 0px !important;
}
@media only screen and (max-width: 768px) {
  .margin-contact .ant-form-item {
    margin-bottom: 0px;
  }
}
.redeem_btn {
  background-color: #ea1b37;
  color: #fff;
  border: none;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 20px;
  height: 100%;
  @include mobile {
    font-size: 16px;
    padding: 10px 15px;
  }
}
.redeem_btn:hover {
  background-color: #ea1b37;
}
.hold{
  color:red
}