.filter-elements {
    text-align: left;
    padding: 0 15px;
  }
  .stops {
    border-top: solid 1px #c3bdbd;
    padding: 15px 5px;
    line-height: 30px;
    .stops-text {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
  }
  
  .star-filters {
    .starRating {
      font-size: 14px;
      color: rgb(246, 149, 45);
    }
    .count {
      margin-left: 5px;
      color: #969dac;
      font-size: 12px;
    }
  }
  
  .tripAd-filters {
    .tripRating {
      font-size: 14px;
      color: #00aa6c;
    }
    .count {
      margin-left: 5px;
      color: #969dac;
      font-size: 12px;
    }
  }
  .side-bar {
    // border: #dedada 1px solid;
    background-color: #fff;
    border-radius: 3px;
    // float: left;
  }
  .clearall {
    color: #a062ce;
    float: right;
    font-size: 12px;
  }
  .filter-top-bar {
    padding: 15px 5px;
  }
  .filter-price {
    padding: 2px;
    float: right;
  }
  .clear-div {
    clear: both;
  }
  .stops-text {
    font-weight: 700;
  }
  .outbound-text {
    font-size: 11px;
    font-weight: 600;
    color: gray;
  }
  .results-count {
    font-size: 21px;
    font-family: Ubuntu;
  }
  .show-more-btn-new {
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    color: #0775e2;
  }
  .activity-side-box .ant-card-body {
    padding: 3px;
  }
.rating-pro span.fa.fa-star{
padding: 0px 10px;
}
.range-collapse-activity .ant-collapse{
  background: transparent;
  border: transparent;
}
  