.hotel-extranet-hotel {
  width: 1100px;
  .ant-form-item {
    margin-bottom: 5px;
  }
  .mb-bottom {
    margin-bottom: 15px;
  }
  .ant-upload {
    width: 90px;
    height: 70px;
  }
}
