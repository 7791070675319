@import "../../assets/variableStyles.scss";

.agent-flight-wrapper {
  background-color: #2f81ed;
  background-image: linear-gradient(to right, #2f80ed, #56ccf2);
  padding-bottom: 20px;

  .ant-form {
    padding: 15px 0;
    .ant-form-item-label {
      label {
        color: #fff;
        font-weight: 600;
      }
    }

    .ant-form-item {
      margin-bottom: 5px;
      input {
        color: #000000;
        font-weight: 600;
      }
      .ant-select-selector {
        height: 40px !important;
        font-weight: 600;
      }
      .ant-select-selection-item {
        line-height: 40px !important;
        font-weight: 600;
        color: #000000;
        font-size: 15px;
      }
      .ant-select-selection-placeholder {
        font-weight: 600;
        color: #000000;
      }
    }

    .ff-agent-search {
      background: #ea1b37 !important;
      text-align: center;
      display: block;
      color: #fff;
      font-size: 16px;
      margin-left: auto;
      border-radius: 5px;
    }
  }

  .coolaspe-airline-op {
    margin-bottom: 15px;
    .ant-collapse {
      background-color: transparent;
      border: 0px solid #d9d9d9;
    }
    .ant-collapse-item {
      border-bottom: 0px solid #d9d9d9;
    }
    .ant-collapse-content {
      background: transparent;
      border-top: 0px solid;
      .ant-collapse-content-box {
        padding: 0;
      }
    }

    .ant-collapse-header {
      color: #ffffff !important;
      display: flex;
      align-items: center;
      font-weight: 600;
      padding-left: 0;
    }

    label {
      color: #333;
      font-weight: normal;
    }
    .restrict-select {
      color: #333;
      font-weight: 600;
      span {
        margin-right: 10px;
        color: #fff;
      }
    }
  }
}
