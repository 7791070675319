@import "../../assets/variableStyles.scss";
.managebus-table {
    .ant-table-content {
        &::-webkit-scrollbar {
            display: none;
          }
    }
}
.fare-table {
    .ant-table-content {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
    }
}
.operating-date-picker {
    .ant-picker {
        width: 100%;
    }
}
.layout-type {
    font-size: 14px;
}
.lower-layout {
    ul {
        display: flex;
        li {
            border: 1px solid #ccc;
            height: 35px;
            width: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .active {
            background-color: green;
            color: #fff;
        }
    }
}
.upper-layout {
    ul {
        display: flex;
        li {
            border: 1px solid #ccc;
            height: 35px;
            width: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .active {
            background-color: blue;
            color: #fff;
        }
    }
}
.img-block {
    img {
        transform: rotate(270deg);
    }
}
.seat-quota {
    max-width: 53%;
    @include mobile {
        max-width: 100%;
    }
    .single-seat {
         justify-content: flex-end !important;
         margin-right: 2px;
    }
}
.selected-seat-form {
    .ant-form-item-label {
        text-align: start;
    }
}