@import "../../../assets/variableStyles.scss";

.cart-body .ant-card-body {
  padding: 0px !important;
}

.activities-cart-wrapper {
  .select-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .activity-cart-card {
    margin-bottom: 10px;
    .ant-card-body {
      padding: 10px;

      .activity-img-wrapper {
        width: 100%;
        img {
          width: 100%;
          height: auto;
        }
      }
      .title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
        margin-left: 10px;
      }

      .subtitle {
        font-weight: 600;
        margin-bottom: 2px;
      }

      .price-wrapper {
        display: flex;
        border-left: 1px solid #f6f7fb;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        height: 100%;
        @include mobile {
          border: 0;
          flex-direction: row;
          justify-content: space-between;
          border-top: 1px solid #f6f7fb;
          padding-top: 10px;
        }

        .delete-btn {
          background-color: #ea1b37;
          border-color: transparent;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          border-radius: 3px;
          padding: 5px 15px;
          height: auto;

          &:focus,
          &:active,
          &:hover {
            background-color: #d31831 !important;
            color: #fff;
          }
        }
      }
    }
  }
}

.card-value-img {
  margin-bottom: 10px;
}
.img-cart {
  width: 100px;
  height: 80px;
  margin-left: 11px;
  padding-bottom: 10px;
}
.activity-pimg {
  padding-top: 7px;
  padding-left: 27px;
  margin-bottom: 0em !important;
}
.activity-pimg1 {
  padding-left: 27px;
  margin-bottom: 0em !important;
}

.activity-pimg2 {
  padding-left: 73px;
  margin-bottom: 0em !important;
}
.activity-pimg3 {
  padding-left: 190px;
}
.activity-pimg4 {
  padding-left: 95px;
}

.activity-pimg5 {
  padding-left: 89px;
}

.delete-cart {
  padding-left: 20px;
}
.edit-cart {
  padding-left: 79px;
}
.sub-total {
  position: absolute;
  right: 20px;
  margin-bottom: 0;
}
.all-total-cost {
  margin-top: 20px;
}
.activity-ptotal {
  padding-left: 270px;
  margin-bottom: 0em !important;
}
.activity-paynow {
  padding-left: 40px;
}
.all-near-places-12 {
  background-color: #ea1b37;
  border-color: transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 3px;
  height: auto;
}
@media screen and (max-width: 767px) {
  .img-cart {
    margin-left: 0px;
  }
  .activity-pimg {
    padding-left: 0px;
    margin-bottom: 0em !important;
  }
  .activity-pimg1 {
    padding-left: 0px;
    margin-bottom: 0em !important;
  }
  .activity-pimg3 {
    padding-left: 0px;
  }
  .activity-pimg4 {
    padding-left: 0px;
  }
  .delete-cart {
    padding-left: 0px;
  }
  .edit-cart {
    padding-left: 0px;
  }
  .sub-total {
    bottom: -10px;
    position: absolute;
    right: 0;
    left: 13px;
  }

  .border-cart {
    border-top: 1px solid #000;
    margin-top: 30px;
  }

  .activity-pimg2 {
    padding-left: 0px;
    margin-bottom: 0em !important;
  }
  .activity-paynow {
    padding-top: 7px;
    padding-left: 0px;
  }
  .activity-ptotal {
    padding-left: 0px;
  }
  .activity-paynow {
    padding-left: 0px;
  }
}
.all-near-places-12 {
  background-color: #e2076a;
  border-color: transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 3px;
  height: auto;
}
.checkall {
  font-weight: bold;
}
