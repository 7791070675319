@import "../../assets/variableStyles.scss";

.currency-flag-img {
  max-width: 15px;
  max-height: 12px;
  margin-right: 10px;
}

.selectedNav {
  color: #da2027 !important;
}

.nav-wallet-wrapper {
  padding: 5px 12px;
  .anticon {
    font-size: 16px;
  }
}
.user-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .anticon {
    margin: 0 5px;
  }
}

.header-bg {
  background-color: #00164d;
}
.header-container {
  background: #ffffff;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 70;
  .wrapper {
    max-width: 1240px;
    background-color: transparent;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;

    .header {
      width: 100%;
      background-color: transparent;
      display: flex;

      .app-logo {
        .company-logo {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 0px 0;
          //max-width: 85px;
          @include mobile {
            justify-content: start;
            max-width: 80px;
          }
          @include smmobile {
            max-width: 70px;
          }
          img {
            height: 56px;
            width: auto;
            margin-top: -8px;
            margin-bottom: 3px;
          }
        }
        @include mobile {
          // width: initial;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0;
        }
      }
      .app-links {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 12px;
        @include mobile {
          align-items: flex-start;
        }
        ul {
          display: flex;

          padding: 0;
          list-style: none;
          overflow: hidden;
          margin-left: auto;
          @include mobile {
            display: block;
          }
          li {
            padding: 15px;
            display: flex;
            align-items: center;
            height: 100%;
            border-right: 1px solid #c7c7c7;
            padding: 0px 9px;
            font-size: 15px;
            @include mobile {
              padding: 4px 0;
              border-right: 0px solid #c7c7c7;
            }
            a {
              display: flex;
              color: #000;
              align-items: center;
              text-decoration: none;
              font-size: 15px;
              @include mobile {
                color: #ffffff;
              }

              .anticon {
                margin-left: 8px;
              }
            }
            span {
              font-size: 15px;
              color: #000000;
              @include mobile {
                color: #ffffff;
              }
            }
          }
          .custom-drop-down {
            position: initial;
            a {
              color: #fff;
            }
            .dropdown-item {
              color: #000 !important;
            }
          }
        }
        @media screen and (max-width: 768px) {
          flex-flow: column;
          width: 100%;
          text-align: left;
        }
      }
    }
    .header li a:hover,
    .header .menu-btn:hover {
      color: #bcd5f5;
      text-decoration: none !important;
      outline: none !important;
    }

    /* menu */
    .header .menu {
      max-height: 0;
      transition: max-height 0.2s ease-out;
    }

    /* menu icon */
    .header {
      .menu-icon {
        cursor: pointer;
        display: inline-block;
        padding: 28px 20px;
        position: relative;
        user-select: none;
        @include mobile {
          padding: 28px 0 28px 20px;
        }
        .navicon {
          background: #fff;
          display: block;
          height: 1px;
          position: relative;
          transition: background 0.2s ease-out;
          width: 18px;
        }
      }
      .user-icon {
        display: flex;
        align-items: center;
        i {
          margin-right: 20px;
          font-size: 20px;
          color: #fff;
        }
        .menu-icon {
          margin-bottom: 0;
          padding: 22px 2px 28px 2px;
          margin-left: 18px;
        }
        .menu-text {
          color: #fff;
          font-size: 10px;
          position: absolute;
          top: 30px;
          left: -2px;
        }
      }
    }

    .header .menu-icon .navicon:before,
    .header .menu-icon .navicon:after {
      background: #fff;
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      transition: all 0.2s ease-out;
      width: 100%;
    }

    .header .menu-icon .navicon:before {
      top: 5px;
    }

    .header .menu-icon .navicon:after {
      top: -5px;
    }

    /* menu btn */
    .header .menu-btn {
      display: none;
    }

    .header .menu-btn:checked ~ .menu {
      max-height: 430px;
    }

    .header .menu-btn:checked ~ .menu-icon .navicon {
      background: transparent;
    }

    .header .menu-btn:checked ~ .menu-icon .navicon:before {
      transform: rotate(-45deg);
    }

    .header .menu-btn:checked ~ .menu-icon .navicon:after {
      transform: rotate(45deg);
    }

    .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
    .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
      top: 0;
    }
    .iconText {
      margin-left: 5px;
    }
    .service-links {
      align-items: center;
      .nav-link {
        color: white !important;
        font-size: 16px;
        i {
          margin-left: 5px;
          font-size: 15px;
        }
      }
    }
    @media (min-width: 992px) {
      // .header li {
      //   // float: left;
      // }
      // .header li a {
      //   // padding: 23px 30px;
      // }
      .header .menu {
        max-height: none !important;
      }
      .header .menu-icon {
        display: none;
      }
    }
    @media screen and (max-width: 768px) {
      .header {
        padding: 0 15px;
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        // background: #00164d;
        background-color: #2f81ed;
        background-image: linear-gradient(to right, #2f80ed, #56ccf2);
        border-bottom: 0px solid #ffffff;
        a {
          img {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

.dashboard-menu {
  max-width: 300px;
}
.menu {
  align-items: center;

  .currency-text {
    .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
    }
    .ant-select-arrow {
      display: flex;
      align-items: center;
    }
  }
}
.curreny-dropdown {
  padding: 20px 0 20px 30px;
}
.ant-select-dropdown {
  z-index: 9999999999 !important;
}
.login-modal {
  width: 600px !important;

  .ant-modal-close-x {
    color: #000 !important;
    font-size: 20px !important;
    line-height: 30px !important;
  }
  .ant-modal-footer {
    display: none;
  }
  .close-modal-icon {
    padding-bottom: 15px;
    font-size: 20px;
    color: #000;
    text-align: end;
  }
  .ant-form-item {
    .ant-form-item-control {
      min-width: 100%;
    }
  }
  .ant-input-affix-wrapper::before {
    display: none;
  }
  .ant-form {
    padding: 10px 10px;
    @include mobile {
      padding: 10;
    }
  }
  .login-text {
    text-align: center;
  }
  .forgot-text {
    font-size: 14px;
    color: #0775e2;
    text-align: center;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .ant-col {
    margin-left: 0 !important;
    text-align: center;
    button {
      width: 100%;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .login-alternatives {
    display: flex;
    margin: 15px 0;
    .fb-btn {
      width: 50%;
      text-align: center;
      padding: 10px 0;
      box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);
      margin-right: 10px;
      p {
        margin-bottom: 0;
      }
      img {
        height: 20px;
        text-align: center;
      }
    }
    .google-btn {
      width: 50%;
      text-align: center;
      padding: 10px 0;
      box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.2);
      p {
        margin-bottom: 0;
      }
      img {
        height: 20px;
        text-align: center;
      }
    }
  }
  .or-text {
    p {
      font-size: 14px;
      color: #000;
      text-align: center;
    }
  }
}

@include mobile {
  .mob-none {
    display: none !important;
  }
  .company-logo img {
    margin-top: 4px !important;
    height: 40px !important;
  }
  .tab-content ul li {
    display: inline-grid !important;
  }
  .login-register-mob {
    display: none !important;
  }
}
.mobile-user {
  color: #fff;
  font-size: 20px;
  .mobile-user-icon {
    display: flex;
    align-items: center;
    .anticon {
      vertical-align: middle !important;
      padding: 0 5px;
      margin-right: 5px;
    }
  }
}
.responive-more {
  li {
    a {
      font-size: 16px;
      margin-left: 5px;
      text-decoration: none;
    }
  }
}
.supportvalue {
  display: block;
  text-decoration: none;
  font-size: 16px;
  color: #fff !important;
}
.cre-amount {
  color: red !important;
  font-weight: 600;
}
.cre-amount-1 {
  font-weight: 600;
}
.cre-amount-14 {
  font-weight: 600;
  background-color: #ea1b37;
  color: #fff !important;
}
/*css--added*/
.all-top-menu {
  max-height: 0px;
  display: flex;
  float: right;
  padding-top: 2px;
  padding-bottom: 25px;
}
.all-top-menu li {
  padding: 0px 5px;
  font-size: 15px;
}
.last-mob-bdr .ant-select-selector {
  border: 0px solid #d9d9d9 !important;
}

.cart-nav-top {
  padding-top: 4px !important;
}
.cart-nav-top a {
  color: #000 !important;
}
.menu-bdr-nav .ant-dropdown-menu-item {
  border-bottom: 1px solid #000;
}
.balannce-top-ss {
  padding-top: 4px !important;
}
@media screen and (max-width: 768px) {
  .currency-panel-text {
    position: absolute;
    /* z-index: 10; */
    top: -163px;
    right: 84px;
  }
  #only-mob-user {
    display: block !important;
  }

  .currency-panel-text .ant-select-selector {
    padding: 0px 4px !important;
  }
}

#only-mob-user {
  display: none;
}
