@import "../../../../assets/variableStyles.scss";
.card-results {
    margin-bottom: 20px;
    box-shadow: 0 0 2px 0 #969dac;
    border-radius: 8px!important;
    .ant-card-body {
        padding: 15px;
    }
}
.travels-name {
    h2 {
        color: #0775e2;
        font-size: 18px;
        margin-bottom: 10px;
    }
}
.selected-bus-type {
    p {
        color: #969dac;
        font-size: 12px;
        font-weight: 700;
    }
}
.available-price-details {
    text-align: end;
    @include mobile {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        border-top: 1px solid #969dac;
        padding-top: 10px;
        align-items: center;
    }
    .starting-price {
        margin-bottom: 10px;
        @include mobile {
            display: flex;
            margin-bottom: 0;
            align-items: center;
            margin-left: 5px;
            p {
                margin-bottom: 0;
            }
        }
        span {
            color: #0775e2;
        }
        .vehicle-type{
            font-size: 15px;
            background-color: transparent;
            color: #e2076a;
            width: 75px;
            margin-left: auto;
            margin-bottom: 0;
            border-radius: 5px;
            text-align: center;
            @include mobile {
                margin-right: 10px;
            }
        }
        .starting-price-number {
            font-size: 16px;
            font-weight: 600;
            @include mobile {
                margin-bottom: 0;
            }
        }
    }
    .details {
        background-color: #c4dcf5;
        color: #fff;
        padding: 5px;
        border-radius: 5px;
        width: 75px;
        margin-left: auto;
        text-align: center;
        span {
            font-size: 14px;
            color: #0775e2;
        }
    }
}
.modify-class-type-selector {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.image-block {
    img {
        height: 95px;
        width: 110px;
    }
}
.transport-names {
    width: 100%;
    h2 {
        font-size: 18px;
        color: #000;
        margin-bottom: 5px;
    }
    .capacity {
        font-size: 15px;
        color: #969dac;
        margin-bottom: 2.5rem
    }
}
.images-card-block {
    display: flex;
    height: 100%;
}
.validity {
    background-color: antiquewhite;
    color: #f3b365;
    width: 100px;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 0;
}
.normal {
    color: #0775e2;
}